.about-pickup {
  padding-top: 20px;
}

.about-pickup__banner {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  margin-bottom: 30px;
  background-color: var(--m-yellow);
}

.about-pickup__banner-column {
  display: flex;
  margin-bottom: 20px;
}

.about-pickup__banner-left {
  width: 60%;
}

.about-pickup__banner-left svg {
  width: 100%;
}

.about-pickup__banner-right {
  width: 40%;
}

.about-pickup__banner-right svg {
  width: 100%;
}

.about-pickup__banner-bottom-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 23px;
}

.about-pickup__container {
  padding: 0 20px;
}

.about-pickup__text-fullwidth {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 24px;
}

.about-pickup__guide {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.about-pickup__guide-left-column {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}

.about-pickup__guide-right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.about-pickup__guide-stage {
  margin-bottom: 20px;
}
.about-pickup__bottom-text-center {
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .about-pickup__banner {
    padding: 70px 88px 55px 45px;
    margin-bottom: 64px;
  }

  .about-pickup__container {
    padding: 0;
  }

  .about-pickup__guide {
    min-height: 360px;
    margin-top: 64px;
  }

  .about-pickup__guide-left-column,
  .about-pickup__guide-right-column {
    display: flex;
    width: 332px;
  }

  .about-pickup__divider {
    width: 2px;
    background-color: var(--m-black-disabled);
  }

  .about-pickup__guide-right-column {
    padding-left: 30px;
  }

  .about-pickup__guide-stage {
    margin-bottom: 0;
  }

  .about-pickup__bottom-text-center {
    margin-top: 64px;
  }
}

@media (min-width: 1440px) {
  .about-pickup__banner {
    padding: 70px 88px 55px 45px;
    margin-bottom: 84px;
  }

  .about-pickup__container {
    padding: 0 45px;
  }

  .about-pickup__text-fullwidth {
    font-size: 16px;
    line-height: 24px;
  }

  .about-pickup__guide {
    min-height: 400px;
    margin-top: 84px;
  }

  .about-pickup__guide-left-column,
  .about-pickup__guide-right-column {
    width: 300px;
  }

  .about-pickup__bottom-text-center {
    width: 500px;
    margin: 0 auto;
    margin-top: 84px;
    text-align: center;
  }
}


.about__connect {
  margin-bottom: 32px;
}

.about__contacts {
  margin-bottom: 24px;
}

.about__half-block {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}

.about__full-block {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.about__subheader-social {
  margin-bottom: 15px;
}

.about__contacts a,
.about__social a {

}

.about__social {

}

.about__social-link {
  padding: 0 7px;
  display: flex;
  align-items: center;
}

.about__social-link span {
  margin-left: 15px;
}

.about__addresses-header {
  margin-bottom: 12px;
}

.about__address {
  margin-bottom: 16px;
}



.about__read-header {
  margin-bottom: 5px;
  padding: 0 16px;
}

.about__press {
  display: block;
  box-sizing: border-box;
  padding: 0 16px;
  padding-top: 24px;
  border-bottom: 2px solid var(--m-light-grey);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}

.about__press:active {
  background-color: var(--m-yellow);
}

@media (hover: hover) and (pointer: fine) {
  .about__press:hover {
    background-color: var(--m-yellow);
  }
}

.about__press--selected {
  background-color: var(--m-yellow);
}

.about__press-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about__press-link {

}

.about__press-link span {
  display: flex;
  align-items: center;
}

.about__press-link span {
  margin-left: 8px;
}

.about__press-text {
  padding-top: 12px;
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .about {

  }

  .about__top-info {
    /* padding: 0; */
    /* margin-bottom: 32px; */
  }

  .about__connect,
  .about__addresses {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
  }

  .about__connect {
    margin-right: 67px;
    margin-bottom: 0;
  }

  .about__read-header {
    margin-bottom: 12px;
  }

  .about__press-blocks {
    display: flex;
    flex-wrap: wrap;
  }

  .about__press {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
    margin-right: 67px;
  }

  .about__press:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1440px) {
  .about {
    padding-left: 30px;
    padding-right: 95px;
  }

  .about__top-info {
    padding: 0;
  }

  /* .about__top-info {
    padding: 0;
    margin-bottom: 32px;
  }

  .about__connect,
  .about__addresses {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
  } */

  /* .about__connect {
    margin-right: 67px;
    margin-bottom: 0;
  } */

  .about__header,
  .about__contacts,
  .about__social,
  .about__read-header {
    padding: 0;
  }

  .about__header {
    position: static;
    margin-bottom: 30px;
  }

  /* .about__read-header {
    margin-bottom: 12px;
  } */

  /* .about__press-blocks {
    display: flex;
    flex-wrap: wrap;
  }

  .about__press {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
    margin-right: 67px;
  }

  .about__press:nth-child(2n) {
    margin-right: 0;
  } */
}
