.account {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 38px;
  background: #F0F0F0;
}

.account__col {
  display: flex;
  flex-direction: column;
}

.account__col--map-modal {
  position: fixed;
  z-index: 73;
  top: 0;
  right: -100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.account__col--map-modal-visible {
  top: 0;
  left: auto;
  right: 0;
}

.account__address--map-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.account__address_map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.account__address--map-placemark {
  position: absolute;
  top: 23px;
  left: 0px;
  width: 100px;
  text-align: center;
  font-family: "Geometria Medium";
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32px;
  /*background-color: white;*/
}

.account__address--map-address, .account__address--map-save,
.account__address--map-close, .account__address--map-close-mobile {
  position: fixed;
  bottom: 160px;
  font-family: "Geometria Regular";
  font-size: 24px;
  width: 100%;
  text-align: center;
}

.account__address--map-address {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account__address--map-address-text {
  width: 300px;
  background-color: rgba(255,255,255,0.8);
  text-align: center;
  display: inline-block;
  border-radius: 8px;
  transition: all .3s;
  padding: 5px;
  height: 0;
  opacity: 0;
}

.account__address--map-address-text-active {
  opacity: 1;
  height: auto;
}

.account__address--map-save {
  bottom: 80px;
  opacity: 0;
  transition: transform .2s ease-in;
}

.account__address--map-save-visible {
  /*transform: translateY(0);*/
  opacity: 1;
}

.account__address--map-save .button {
  color: var(--m-black);
  background-color: var(--m-yellow);
  border-radius: 8px;
  padding: 10px 10px;
  font-family: "Geometria Regular";
  font-size: 20px;
}

.account__address--map-save .button:disabled {
  color: var(--m-black-50);
  background-color: var(--m-yellow-50);
  border-radius: 8px;
  padding: 10px 10px;
  font-family: "Geometria Regular";
  font-size: 20px;
}

.account__address--map-close, .account__address--map-close-mobile {
  display: none;
  width: 40px;
  top: 10px;
  right: 20px;
  bottom: auto;
}

.account__address-map-disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
}

.account__address--map-close button, .account__address--map-close-mobile button {
  border: none;
  background-color: transparent;
}

.account__address--map-close-mobile {
  display: block;
}

.account__col-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account__col-item-full {
  width: 100%;
}

.account__col-item-half {
  width: 49.9%;
}

.account__col-item-two-thirds {
  width: 66.6%;
}

.account__col-item-one-third {
  width: 33.3%;
}

.account__small-inputs .input-block {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-bottom: 32px;
  width: calc(50% - 8px);
}

/*.account__col-item-full > div, .account__col-item-half, .account__col-item-two-thirds, .account__col-item-one-third {*/
/*  padding: 10px;*/
/*}*/

.account__col--text-header {
  font-family: 'Geometria Bold';
  font-size: 22px;
  line-height: 30px;
  color: var(--m-black);
}

.account__col--button button,
.account__col--button-tablet button,
.account__col--button-mobile button {
  border: none;
  display: flex;
  background-color: transparent;
}

.account__col--button, .account__col--button-tablet {
  display: none;
}

.account .modal-header, .modal-header button {
  background: inherit;
}

.account .modal-header {
  padding-left: 15px;
  padding-right: 15px;
}

.account__loyalty {
  padding: 2px 10px 0;
  background: #FFBF00;
  border-radius: 20px;
  font-family: 'Geometria Regular';
  font-size: 18px;
}

.account__info, .account__info--negative, .account__info--negative-desktop {
  border-radius: 20px;
  border: 1px solid #6D6D6D;
  padding: 8px 10px 6px 10px;
  font-size: 17px;
  position: relative;
  height: 64px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 10px;
  width: 50%;
}

.account__info--negative, .account__info--negative-desktop {
  color: white;
  background: black;
  width: 50%;
  margin-right: 0;
}

.account__info--negative-desktop {
  display: none;
}

.account__info > .account__edit-info {
  position: absolute;
  right: 6px;
  top: 10px;
}

.account__col--points-wrapper, .account__col--address-wrapper {
  background: white;
  height: 74px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font-family: 'Geometria Bold';
  font-size: 62px;
  box-sizing: border-box;
}

.account__col--points-wrapper span:nth-child(1) {
  padding-left: 10px;
  padding-top: 9px;
  width: 50%;
  margin-right: -10px;
}

.account__col--points-wrapper span:nth-child(2) {
  padding: 10px;
  font-family: "Geometria Regular";
  font-size: 14px;
  color: #6D6D6D;
  border-left: 1px solid #6D6D6D;
}

.account__col--address-wrapper {
  padding: 10px;
  height: auto;
  flex-direction: column;
}
.account__col--points {

}

.account__button-link {
  cursor: pointer;
  font-family: "Geometria Regular";
  font-size: 17px;
  padding: 5px 20px 3px 20px;
  border: 1px solid #6D6D6D;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 44px;
}

/*.account__info > div {*/
/*  margin-bottom: 10px;*/
/*}*/

.account__edit-info {
  display: flex;
  align-items: center;
  color: var(--m-black);
}

.account__edit-info-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.account__edit-info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.account__addresses {
  margin-bottom: 40px;
}

.account__address {
  margin-bottom: 18px;
  padding: 20px 60px 32px 20px;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.account__address-top {
  margin-bottom: 11px;
}

.account__addresses-header {
  margin-bottom: 16px;
}

.account__orders {
  display: flex;
  flex-direction: column;
}

.account__order--row {
  position: relative;
  padding: 14px 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  background-color: var(--m-white);
  margin-bottom: 8px;
  box-sizing: border-box;
  align-items: stretch;
}

.account__order--column-items {
  display: flex;
  height: 100%;
}

.account__orders--item {
  position: relative;
  flex: 0 0 auto;
  margin-right: 16px;
  height: 100%;
  white-space: nowrap;
  display: flex;
}

.account__orders--item-image, .account__orders--item-image-alt {
  background-color: var(--m-yellow);
  border-radius: 10px;
  margin-right: 10px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.account__orders--item-image img {
  width: 100%;
}

.account__orders--item-image-alt img {
  width: 200%;
  margin-top: -48px;
  margin-left: -29px;
}

.account__orders--item-name-wrapper {
  display: flex;
  flex-direction: column;
}

.account__orders--item-name {
  max-width: 100px;
  font-size: 16px;
  white-space: normal;
  line-height: 16px;
}

.account__orders--item-weight {
  font-size: 9px;
  color: var(--m-gray-promo);
}
.account__orders-header {
  margin-bottom: 24px;
}

.account__order {
  margin-bottom: 16px;
  padding-bottom: 24px;
  border-bottom: 2px solid var(--m-light-grey);
}

.account__order:last-child {
  margin-bottom: 0;
}

.account__order-top {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 20px;
  min-width: 230px;
}

.account__order-top-date {
  font-size: 18px;
  white-space: nowrap;
}

.account__order-middle {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account__order-copy-btn {
  background-color: var(--m-yellow);
  display: flex;
  padding: 6px 10px 4px;
  font-size: 18px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.account__order-copy-btn-icon {
  margin-right: 5px;
}

.account__order-dish {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 17px;
  width: calc(30% - 11px);
}

.account__order-dish:nth-child(3n) {
  margin-right: 0;
}

.account__order-dish img {
  width: 100%;
}

.account__order-dish span {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--m-black);
}

.account__all-orders-link {
  margin-bottom: 24px;
  padding: 20px;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.account__all-orders-link-header {
  margin-bottom: 8px;
}

.account__logout {
  text-align: center;
}

.account__logout span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.account__logout--desktop {
  display: none;
}

.account__address_map_wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  padding-top: 74px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 70;
}

.account__address--map-search-block {
  position: absolute;
  z-index: 80;
  width: calc(100% - 20px);
  left: 10px;
  top: 60px;
  background-color: var(--m-yellow);
  padding: 20px;
  box-shadow: 2px 2px 10px grey;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: height 0.3s;
}

.account__address--map-search-block .input-block__input {
  background-color: var(--m-white);
  min-height: auto;
}
.account__address--map-search-block .input-block__input input {
  height: 40px;
}
.account__address--map-search-block-result {
  font-family: 'Geometria Regular';
  font-size: 16px;
  opacity: 1;
  padding-top: 0;
}

.account__address--map-search-block-result-appear {
  animation: 0.3s disappear backwards ease;
  padding-top: 8px;
}

.account__col--points-wrapper .account__col--points-dimmed {
  font-size: 14px;
  width: 80%!important;
  padding-top: 0!important;;
  line-height: 16px;
  font-family: 'Geometria Medium';
}
/*.account__address_map_wrapper::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: rgba(0,0,0,0.2);*/
/*}*/

@media (min-width: 768px) {
  .account {
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 68px;
  }

  .account__col--button-tablet {
    display: block;
    position: absolute;
    top: -55px;
    right: -10px;
  }

  .account__col--button-mobile {
    display: none;
  }

  .account__left {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
    margin-right: 32px;
  }

  .account__right {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
  }

  .account__right .account__link {
    text-align: right;
  }

  .account__link {
    text-decoration: underline;
    cursor: pointer;
  }

  .account__address--map-close {
    display: block;
    top: 20px;
    right: 30px;
  }

  .account__col-white-header-bg-tablet {
    background-color: var(--m-white);
    padding: 22px;
    display: flex;
    justify-content: space-between;
  }

  .account__col--points-wrapper {
    flex-direction: column;
    align-content: flex-start;
    justify-content: normal;
    align-items: flex-start;
  }

  .account__col-item .account__info:nth-child(1) {
    margin-bottom: 10px;
  }

  .account__info-wrapper {
    flex-direction: column;
    box-sizing: border-box;
  }

  .account__info-wrapper .account__info,
  .account__info-wrapper .account__info--negative,
  .account__info-wrapper .account__info--negative-desktop
  {
    width: 100%;
    box-sizing: border-box;
    margin-right: 0;
  }

  .account__col--address-wrapper {
    padding: 0;
  }

  .account__address--map-close-mobile {
    display: none;
  }

  .account__col-item-full-tablet {
    width: 100%;
  }

  .account__col-item-half-tablet  {
    width: 49.9%;
  }

  .account__col-item-two-thirds-tablet  {
    width: 66.6%;
  }

  .account__col-item-one-third-tablet  {
    width: 33.3%;
  }

  .account__col-item-auto-tablet {
    width: auto;
  }

  .account__col--points-wrapper {

  }

  .account__col--points-wrapper span {
    width: 100%;
  }

  .account__col--points-wrapper span:nth-child(1) {
    padding-left: 0;
    padding-top: 0;
    margin-right: 0;
    width: 100%;
  }
  .account__col--points-wrapper span:nth-child(2) {
    border-left: none;
    padding: 0;
  }

  .account__info {
    border-radius: 14px;
  }

  .account__info--negative {
    display: none;
  }

  .account__info--negative-desktop {
    flex-direction: initial;
    height: 44px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 14px;
    display: flex;
    transition: all 0.5s;
    position: relative;
    cursor: pointer;
  }

  .account__info--negative-desktop-active .account__info--negative-qr-code {
    /*height: auto;*/
    /*padding: 20px 40px;*/
    /*flex-direction: column;*/
    /*transition: all .1s;*/
    /*position: absolute;*/
    /*z-index: 99;*/
    /*width: 256px!important;*/
    height: auto;
    opacity: 1;
    box-shadow: 2px 2px 20px #6D6D6D;
  }

  .account__info--negative-desktop-active:hover {
    opacity: 0.9;
  }

  .account__info--negative-desktop-active svg {
    scale: 0.8;
  }

  .account__info--negative-qr-code {
    position: absolute;
    left: 0;
    background-color: black;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    height: 0;
    opacity: 0;
  }

  .account__address--map-search-block {
    top: 90px;
  }

  .account__col--points-wrapper .account__col--points-dimmed {
    font-size: 14px;
    width: 200px !important;
  }
}

@media (min-width: 1440px) {
  .account__col--button-tablet {
    display: none;
  }

  .account__col--button {
    display: block;
    position: absolute;
    top: -58px;
    right: -10px;
  }

  .account__col--map-modal {
    /*padding-top: 0px;*/
    /*padding-left: 0px;*/
    /*padding-right: 30px;*/
    top: 87px;
    width: 880px;
    border-left: 2px solid var(--m-black);
  }

  .account__address--map-address, .account__address--map-save {
    width: 880px;
  }

  .account__address--map-close {
    top: 100px;
    right: 30px;
  }

  .account__col-item-full-desktop {
    width: 100%;
  }

  .account__col-item-half-desktop {
    width: 49.9%;
  }

  .account__col-item-two-thirds-desktop  {
    width: 66.6%;
  }

  .account__col-item-one-third-desktop  {
    width: 33.3%;
  }
}

