.offer__container {
    font-family: "Geometria Regular";
    font-size: 16px;
    height: 292px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background-color: var(--m-yellow);
    overflow: hidden;
}

.offer__col {
}

.offer__col-label {
    background-color: var(--m-white);
    display: inline-block;
    padding: 1px 8px 4px 8px;
    border-radius: 14px;
    line-height: 25px;
    height: 25px;
    box-sizing: border-box;
}

.offer__col-header-title {
    font-family: "Geometria Bold";
    font-size: 21px;
}

.offer__col-header-link {
    display: none;
}

.offer__col-image-link {
    cursor: pointer;
    display: flex;
    height: 28px;
    line-height: 28px;
    margin: 16px 0 20px 16px;
    padding: 0 8px 4px 8px;
    border-radius: 14px;
    background-color: var(--m-white);
    box-sizing: border-box;
}

.offer__col-text {
    width: 100%;
    padding: 16px;
    height: 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: baseline;
}

.offer__col-image {
    background: url("https://pizzamaestrello.com/img/media/offer-bg.png") no-repeat center center;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: baseline;
    background-size: cover;
}

.offer__col-header-description {
    font-size: 13px;
}

@media (min-width: 768px) {
    .offer__container {
        flex-direction: row;
    }
    .offer__col-text, .offer__col-image {
        height: auto;
    }
    .offer__col-text {
        padding: 25px;
        justify-content: start;
    }
    .offer__col-header-title {
        font-size: 28px;
        margin-top: 20px;
    }
    .offer__col-header-description {
        font-size: 16px;
        margin-top: 20px;
    }
    .offer__col-header-link {
        cursor: pointer;
        display: flex;
        margin-top: 40px;
        text-decoration: underline;
        align-items: center;
    }
    .offer__col-header-link svg {
        margin-right: 10px;
    }
    .offer__col-image-link {
        display: none;
    }
    .offer__col-text {
        width: 60%;
    }
    .offer__col-image {
        width: 40%;
        background-size: cover;
    }
}

@media (min-width: 1440px) {
    .offer__col-header-link {
        margin-top: 40px;
    }
}
