.footer {
  margin-left: 16px;
  padding-top: 140px;
  padding-bottom: 50px;
}

.footer a {
  display: flex;
  align-items: flex-end;
  color: var(--m-black-50);
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
}

.footer span {
  margin-right: 8px;
  display: block;
}

@media (min-width: 1440px) {
  .footer {
    margin-left: 208px;
  }
}
