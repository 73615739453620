.dish {
  position: fixed;
  z-index: 81;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  padding-bottom: 140px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 2px 2px 40px var(--m-black-50);
}

/* .dish::after {
  content: '';
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 44px;
  height: 2px;
  background-color: var(--m-black);
} */

.dish--visible {
  top: 0;
}

.dish__before_top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.dish__top {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 16px;
}

.dish__name {
  margin: 0;
  width: 70%;
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 1.2;
}

.dish__name--hidden-on-mobile {
  display: none;
}

.dish__price {
  width: 30%;
  text-align: right;
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 1.2;
}

.dish__price--hidden-on-mobile {
  display: none;
}

.dish__separator {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Geometria Narrow Regular';
  color: var(--m-black-50);
}

.dish__nameru {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Geometria Narrow Regular';
  color: var(--m-black-50);
}

.dish__info {
  margin-bottom: 32px;
}

.dish__left {
  position: relative;
  box-sizing: border-box;
  /* padding: 0 16px; */
  width: 100%;
  background-color: white;
}

.dish__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dish__left--selected .pizza-bg-part {
  fill: var(--m-yellow);
}

.dish__right {
  /* display: block; */
}

.dish__ingridients {
  margin-top: 30px;
  margin-bottom: 8px;
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-50);
}

.dish__params {
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-18);
}

.dish__empty-img {
  box-sizing: border-box;
  padding-left: 22.55px;
  padding-right: 36.72px;
  width: 100%;
  height: auto;
}

.dish__content-img {
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 10;
  display: block;
  box-sizing: border-box;
  padding-left: 22.55px;
  padding-right: 36.72px;
  width: 100%;
  height: auto;
}

.dish__add {
  position: absolute;
  z-index: 15;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 44px;
  height: 44px;
  background-color: var(--m-black);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dish__add-in-cart {
  display: none;
}

.dish__add--activated {
  display: flex;
  justify-content: center;
  width: 120px;
  border-radius: 50px;
}

.dish__add-minus,
.dish__add-plus {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--m-black);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dish__add-plus {
  right: 0;
}

.dish__add-minus {
  left: 0;
  display: none;
  opacity: 0;
}

.dish__add-minus svg {
  display: none;
}

.dish__add-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 30px;
  color: white;
  opacity: 0;
  transition: all 0.2s ease;
}

.dish__add-amount--visible {
  opacity: 1;
  animation: 0.3s 0.3s appear backwards ease;
}

.dish__add-minus--visible {
  display: flex;
  opacity: 1;
  animation: 0.3s 0.3s appear backwards ease;
}

.dish__add-minus--visible svg {
  display: inline;
}

.dish__add-plus:active,
.dish__add-minus:active {
  opacity: 0.5;
}

.dish__feature {
  position: absolute;
  z-index: 20;
  top: -14px;
  left: 0;
  padding: 6px 12px;
  border-radius: 102px;
  color: white;
  background-color: var(--m-black);
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
}

.dish__feature--spicy {
  background-color: var(--m-red);
}

.dish__feature--vegetarian {
  background-color: var(--m-green);
}

.dish__feature--vegan {
  background-color: var(--m-green-second);
}

.dish__feature--fish {
  background-color: var(--m-blue);
}

.dish__feature--new, .dish__feature--santa {
  background-color: transparent;
}

.card__feature--smstretching {
}

.dish__additional-header {
  margin-bottom: 16px;
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 30px;
}

.dish__additions {
  display: flex;
  flex-wrap: wrap;
}

.dish__addition {
  margin-right: 17px;
  margin-bottom: 16px;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 8.5px);
}

.dish__addition:nth-child(2n) {
  margin-right: 0;
}

.dish__addition-top {
  position: relative;
  z-index: 5;
  display: flex;
}

.dish__addition-img {
  width: 50%;
  margin-bottom: 28px;
}

.dish__addition-img img {
  width: 100%;
}

.dish__addition-check {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s;
}

.dish__addition-check--visible {
  opacity: 1;
}

.dish__addition-name {
  position: relative;
  z-index: 5;
  margin-bottom: 8px;
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.dish__addition-params {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
}

.dish__addition-weight {
  font-family: 'Geometria Narrow Regular';
  color: var(--m-black-50);
}

.dish__addition-price {
  color: var(--m-black);
}

.dish__addition input {
  display: none;
}

.dish__addition label {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 13px;
  width: 100%;
  height: 100%;
  background-color: white;
  cursor: pointer;
}

.dish__addition-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.dish__addition input:checked ~ label .dish-bg-part {
  fill: var(--m-yellow);
}

.dish__close {
  display: none;
}

.dish__close {
  position: absolute;
  top: 20px;
  right: 37px;
  font-size: 0;
}

.dish__close button, .dish__mobile-close button {
  padding: 0;
  border: none;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dish__close button:active, .dish__mobile-close button:active {
  opacity: 0.7;
}

@media (hover: hover) and (pointer: fine) {
  .dish__addition:hover label .dish-bg-part {
    fill: var(--m-light-grey-50);
  }

  .dish__addition input:checked:hover ~ label .dish-bg-part {
    fill: var(--m-yellow-50);
  }
}

@media (min-width: 768px) {
  .dish {
    padding-top: 64px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 140px;
  }

  .dish__top {
    display: none;
  }

  .dish__name {
    width: 100%;
    margin-bottom: 8px;
  }

  .dish__name--hidden-on-mobile {
    display: block;
  }

  .dish__price {
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
  }

  .dish__price--hidden-on-mobile {
    display: block;
  }

  .dish__ingridients {
    margin-top: 0;
  }

  .dish__info {
    margin-bottom: 52px;
  }

  .dish__left {
    margin-right: 44px;
    display: inline-block;
    vertical-align: top;
    width: calc(45% - 22px);
  }

  .dish__right {
    display: inline-block;
    vertical-align: top;
    padding-top: 15px;
    width: calc(55% - 22px);
  }

  .dish__addition {
    width: calc(25% - 12.75px);
  }

  .dish__addition:nth-child(2n) {
    margin-right: 17px;
  }

  .dish__addition:nth-child(4n) {
    margin-right: 0;
  }

  .dish__addition-img {
    margin-bottom: 65px;
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .dish {
    /* padding-top: 64px; */
    padding-left: 15%;
    padding-right: 15%;
    /* padding-bottom: 140px; */
  }

  .dish__add {
    right: 25px;
    bottom: 25px;
  }
}

@media (min-width: 1440px) {
  .dish {
    top: 87px;
    left: auto;
    right: -100%;
    margin-top: 0;
    width: 880px;
    border-left: 2px solid var(--m-black);
    padding-top: 64px;
    padding-left: 32px;
    padding-right: 45px;
    padding-bottom: 140px;
    box-shadow: none;
  }

  .dish--visible {
    top: 87px;
    left: auto;
    right: 0;
  }

  /* .dish__top {
    display: none;
  }

  .dish__name {
    width: 100%;
    margin-bottom: 8px;
  }

  .dish__name--hidden-on-mobile {
    display: block;
  }

  .dish__price {
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
  }

  .dish__price--hidden-on-mobile {
    display: block;
  }

  .dish__ingridients {
    margin-top: 0;
  }

  .dish__info {
    margin-bottom: 52px;
  }

  .dish__left {
    margin-right: 44px;
    display: inline-block;
    vertical-align: top;
    width: calc(45% - 22px);
  }

  .dish__right {
    display: inline-block;
    vertical-align: top;
    padding-top: 15px;
    width: calc(55% - 22px);
  } */

  @media (hover: hover) and (pointer: fine) {
    .dish__left:hover .dish__add {
      width: 148px;
      border-radius: 50px;
    }

    .dish__left:hover .dish__add--activated {
      width: 120px;
    }

    .dish__add:hover {
      background-color: var(--m-dark-grey);
    }

    .dish__add:hover .dish__add-plus {
      opacity: 1;
      background-color: var(--m-dark-grey);
    }

    .dish__add--activated:hover {
      background-color: var(--m-black);
    }

    .dish__add--activated:hover .dish__add-plus {
      background-color: var(--m-black);
    }

    .dish__add--activated .dish__add-plus:hover,
    .dish__add--activated .dish__add-minus:hover {
      opacity: 0.5;
    }

    .dish__left:hover .dish__add-in-cart {
      display: inline-block;
      animation: 0.3s 0.1s appear backwards ease;
    }

    .dish__left:hover .dish__add--activated .dish__add-in-cart {
      display: none;
    }
  }

  .dish__add:active {
    opacity: 0.5;
  }

  .dish__add--activated:active {
    opacity: 1;
  }

  .dish__add-plus:active,
  .dish__add-minus:active {
    transform: scale(0.95);
  }

  .dish__add-in-cart {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    font-family: 'Geometria Narrow Bold';
    font-size: 16px;
    line-height: 30px;
    color: white;
    cursor: pointer;
  }

  .dish__add--activated .dish__add-in-cart {
    display: none;
    animation: 0.3s disappear backwards ease;
  }

  .dish__empty-img {
    padding-left: 28.69px;
    padding-right: 33.51px;
  }

  .dish__content-img {
    top: -22px;
    padding-left: 28.69px;
    padding-right: 33.51px;
  }

  .dish__additional-header {
    margin-bottom: 16px;
    font-family: 'Geometria Narrow Bold';
    font-size: 24px;
    line-height: 30px;
  }

  /* .dish__addition {
    width: calc(25% - 12.75px);
  }

  .dish__addition:nth-child(2n) {
    margin-right: 17px;
  }

  .dish__addition:nth-child(4n) {
    margin-right: 0;
  }

  .dish__addition-img {
    margin-bottom: 65px;
  } */

  .dish__mobile-close {
    display: none;
  }

  .dish__close {
    display: block;
  }
}
