.input-block {
  margin-bottom: 32px;
  position: relative;
}

.input-block--hidden {
  display: none;
}

.input-block:last-child {
  margin-bottom: 0;
}

.input-block__label {
  margin-bottom: 8px;
}

.input-block__label label {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-50);
}

.input-block__error, .input-block__success {
  position: absolute;
  bottom: -22px;
  left: 0;
  font-family: 'Geometria Regular';
  font-size: 12px;
  line-height: 20px;
  color: var(--m-red);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.input-block__error--visible, .input-block__success--visible {
  opacity: 1;
}

.input-block__error--active {
  color: var(--m-green);
}

.input-block__input {
  min-height: 58px;
}

.input-block__input--address {
  position: relative;
}

.input-block__input--address svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
}

.input-block__input input {
  box-sizing: border-box;
  padding: 18px 16px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  border: 1px solid var(--m-black);
  color: var(--m-black);
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  border-radius: var(--input-border-radius);

  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  transition: all 0.2s ease;
}

.input-block__input select {
  box-sizing: border-box;
  padding: 18px 16px;
  max-width: 100%;
  /* min-width: 100%; */
  width: 100%;
  border: 1px solid var(--m-black);
  border-radius: unset;
  color: var(--m-black);
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  border-radius: 0;

  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  transition: all 0.2s ease;
}

.input-block__checkbox {
  font-family: 'Geometria Regular';
  color: var(--m-black-50);
  display: flex;
  align-items: center;
}

.input-block__radio {
  display: flex;
}

.input-block__radio--grey label {
  border-color: var(--m-black-18);
}

.input-block__radio input {
  display: none;
}

.input-block__radio label {
  min-height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 1px solid var(--m-black-18);
  border-radius: var(--input-border-radius);
  font-family: 'Geometria Regular';
  color: var(--m-black-50);
  cursor: pointer;
}

.input-block__radio label.checked {
  background-color: var(--m-light-grey-50);
}

.input-block__radio label:first-child {
  margin-right: 2px;
}

.input-block__input input[type="radio"] {
  width: auto;
}

.input-block__checkbox--error {
  color: var(--m-red);
}

.input-block__checkbox label {
  padding-left: 45px;
}

.input-block__checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.input-block__checkbox input[type='checkbox']:checked ~ .checkmark:after {
  display: block;
}

.input-block__checkbox input[type='checkbox'] ~ .checkmark {
  background-color: #dedede;
  border-radius: 50%;
}

.input-block__checkbox input[type='checkbox']:checked ~ .checkmark {
  background-color: #333333;
  border-radius: 50%;
}

.checkmark {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-block__input--multiple-select select:nth-child(1) {
  margin-right: 8px;
  width: calc(30% - 5.33333px);
}

.input-block__input--multiple-select select:nth-child(2) {
  margin-right: 8px;
  width: calc(40% - 5.33333px);
}

.input-block__input--multiple-select select:nth-child(3) {
  width: calc(30% - 5.33333px);
}

.input-block__input--address input {
  padding-right: 30px;
}

.input-block__input--grey input,
.input-block__input--grey select {
  border-color: var(--m-black-18);
}

.input-block__input--error input,
.input-block__input--error select {
  border-color: var(--m-red);
}

.input-block__input--loading input,
.input-block__input--loading select {
  background-color: var(--m-light-grey);
}

.input-block__input input:focus,
.input-block__input select:focus {
  border-color: var(--m-black-50);
}

.input-block__input input::placeholder {
  color: var(--m-black-50);
  opacity: 1;
}

.input-block__input input:-ms-input-placeholder {
  color: var(--m-black-50);
}

.input-block__input input::-ms-input-placeholder {
  color: var(--m-black-50);
}

.input-block__hidden-input, .input-block__hidden {
  display: none;
}

.input-block__input--hidden {
  display: none;
  transition: all ease-in-out 3s;
  /*animation: hide-input 3s ease-in-out normal;*/
}

.input-block__button--link {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
  cursor: pointer;
  display: block;
  position: absolute;
  width: 100%;
  text-align: end;
}

.input-block__success {
  color: var(--m-green);
}
@keyframes hide-input {
  0% {

  }

  25% {
    transform: rotateY(90deg);
  }

  100% {
    display: none;
    transform: rotateY(180deg);
    box-shadow:  40px 40px 40px #cccccc,
    0 0 0 #ffffff,
    0 0 0 #cccccc inset,
    2px 2px 2px #ffffff inset;
  }
}
