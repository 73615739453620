.conditions {

}

.conditions ul {
  margin: 0;
  padding: 0;
  padding-left: 30px;
}

.conditions li {
  margin-bottom: 20px;
}

.conditions__part {
  position: relative;
  padding-bottom: 32px;
}

.conditions__part::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--m-light-grey);
}

.conditions__header-one {
  margin-bottom: 22px;
}

.conditions__header-two {
  margin-bottom: 16px;
  padding-top: 32px;
}

.conditions__header-three {
  margin-bottom: 32px;
  padding-top: 32px;
}

.conditions__header-four {
  margin-bottom: 32px;
  padding-top: 32px;
}
