.button {
  box-sizing: border-box;
  padding: 15px;
  cursor: pointer;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  border: none;
  outline: none;
  background-color: var(--m-black);
  color: white;
  text-decoration: none;
  transition: all 0.2s ease;
}

.button.yellow {
  background-color: var(--m-yellow);
}

.button--revert {
  background-color: white;
  color: var(--m-black);
}

.button.has-error {
  background-color: var(--m-red);
}

.button--multiple-children {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .button:hover {
  background-color: var(--m-dark-grey);
} */

/* .button:active {
  transform: scale(0.95);
} */

.button:disabled {
  color: var(--m-dark-grey);
  background-color: var(--m-black-disabled);
}

.button:disabled span {
  color: var(--m-dark-grey);
}

.button:disabled:hover {

}

.loader {

}

.loader {
  display: block;
  margin: 0 auto;
  border: 5px solid var(--m-light-grey);
  border-top: 5px solid var(--m-yellow);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
