.categories {
  margin: 0 16px;
  margin-bottom: 40px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 20;
  top: 66px;
  background-color: transparent;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.categories::-webkit-scrollbar {
  display: none;
}

.categories__button {
  display: inline-block;
  padding: 7px 12px 9px 12px;
  margin-right: 2px;
  border: none;
  outline: none;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  color: var(--m-black);
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.categories__button:last-child {
  margin-right: 0;
}

.categories__button--selected {
  color: white;
  font-family: 'Geometria Bold';
  background-color: var(--m-black);
}

.categories__button--styled {
  /*background-color: var(--m-red);*/
  /*color: var(--m-light-grey);*/
  background-color: var(--m-pink);
}

@media (min-width: 1440px) {
  .categories {
    position: absolute;
    top: 0;
    left: 32px;
    width: 126px;
    margin: 0;
    margin-right: 70px;
    white-space: normal;
    overflow-x: auto;
  }

  .categories--scrolled {
    position: fixed;
    top: 87.33px;
    left: 32px;
    margin-right: 0;
  }

  .categories__button {
    box-sizing: border-box;
    display: block;
    margin-right: 0;
    margin-bottom: 2px;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 126px;
  }

  .categories__button--selected {
    margin-bottom: 196px;
  }
}
