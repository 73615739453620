.about {

}

.about__subheader {
  margin-bottom: 4px;
}

.about__top-info {
  padding: 0 16px;
  margin-bottom: 39px;
}

.about__connect {
  margin-bottom: 32px;
}

.about__contacts {
  margin-bottom: 24px;
}

.about__half-block {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}

.about__full-block {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.about__subheader-social {
  margin-bottom: 15px;
}

.about__contacts a,
.about__social a {

}

.about__social {

}

.about__social-link {
  padding: 0 7px;
  display: flex;
  align-items: center;
}

.about__social-link span {
  margin-left: 15px;
}

.about__addresses-header {
  margin-bottom: 12px;
}

.about__address {
  margin-bottom: 16px;
}



.about__read-header {
  margin-bottom: 5px;
  padding: 0 16px;
}

.about__press {
  display: block;
  box-sizing: border-box;
  padding: 0 16px;
  padding-top: 24px;
  border-bottom: 2px solid var(--m-light-grey);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}

.about__press:active {
  background-color: var(--m-yellow);
}

@media (hover: hover) and (pointer: fine) {
  .about__press:hover {
    background-color: var(--m-yellow);
  }
}

.about__press--selected {
  background-color: var(--m-yellow);
}

.about__press-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about__press-link {

}

.about__press-link span {
  display: flex;
  align-items: center;
}

.about__press-link span {
  margin-left: 8px;
}

.about__press-text {
  padding-top: 12px;
  padding-bottom: 24px;
}

.about__delivery-rules {
  margin-top: 32px;
}

@media (min-width: 768px) {
  .about {

  }

  .about__top-info {
    /* padding: 0; */
    /* margin-bottom: 32px; */
  }

  .about__connect,
  .about__addresses,
  .about__delivery-rules {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
  }

  .about__delivery-rules {
    margin-top: 16px;
  }

  .about__connect {
    margin-right: 67px;
    margin-bottom: 0;
  }

  .about__read-header {
    margin-bottom: 12px;
  }

  .about__press-blocks {
    display: flex;
    flex-wrap: wrap;
  }

  .about__press {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
    margin-right: 67px;
  }

  .about__press:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1440px) {
  .about {
    padding-left: 30px;
    padding-right: 95px;
  }

  .about__top-info {
    padding: 0;
  }

  /* .about__top-info {
    padding: 0;
    margin-bottom: 32px;
  }

  .about__connect,
  .about__addresses {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
  } */

  /* .about__connect {
    margin-right: 67px;
    margin-bottom: 0;
  } */

  .about__header,
  .about__contacts,
  .about__social,
  .about__read-header {
    padding: 0;
  }

  .about__header {
    position: static;
    margin-bottom: 30px;
  }

  /* .about__read-header {
    margin-bottom: 12px;
  } */

  /* .about__press-blocks {
    display: flex;
    flex-wrap: wrap;
  }

  .about__press {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 33.5px);
    margin-right: 67px;
  }

  .about__press:nth-child(2n) {
    margin-right: 0;
  } */
}
