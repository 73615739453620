.modal-header {
  position: fixed;
  z-index: 70;
  top: 0;
  right: -100%;
  box-sizing: border-box;
  padding: 0 16px;
  padding-top: 12px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease;
}

.modal-header--no-vertical-center {
  align-items: flex-start;
}

.modal-header--visible {
  right: 0;
}

.modal-header--moved {
  top: 120px;
  background: red;
}

.modal-header__back button {
  display: flex;
  align-items: center;
}

.modal-header__back svg {
  margin-right: 16px;
}

.modal-header button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: white;
}

.modal-header__close {
  display: none;
}

@media (min-width: 1440px) {
  .modal-header {
    top: 87px;
    padding: 27px 30px;
    width: 878px;
  }

  .modal-header--visible {
    top: 87px;
  }

  .about__mobile-close {
    display: none;
  }

  .modal-header__close {
    display: block;
    position: absolute;
    top: 20px;
    right: 37px;
    font-size: 0;
  }

  .modal-header__close {
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}
