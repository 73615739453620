.desktop-nav {
  display: none;
}

@media (min-width: 1440px) {
  .desktop-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .desktop-nav__item {

  }

  .desktop-nav__link {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    padding: 13px 20px 15px 20px;
    border: none;
    background-color: white;
    /* font-family: 'Geometria Regular'; */
    font-family: 'Geometria Narrow';
    font-size: 16px;
    line-height: 20px;
    color: var(--m-black);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .desktop-nav__link--selected {
    font-family: 'Geometria Narrow Bold';
  }

  .desktop-nav__link--catering {
    margin-right: 0;
  }

  .desktop-nav__link--lang {
    width: 32px;
  }

  .desktop-nav__link--account {
    margin: 0;
    margin-left: 24px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desktop-nav__cart {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 32px;
    width: 212px;
  }

  .desktop-nav__cart > button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left: auto;
    width: 64px;
    height: 48px;
    border: none;
    padding: 0;
    border-radius: 100px;
    background-color: var(--m-black);
    color: white;
    font-family: 'Geometria Regular';
    /* font-size: 16px; */
    font-size: 0;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
  }

  .desktop-nav__price {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .desktop-nav__cart svg {
    width: 26px;
    height: 23px;
    transition: opacity 0.2s ease;
  }

  .desktop-nav__cart--is-not-empty > button {
    width: 100%;
    font-size: 16px;
    font-family: 'Geometria Bold';
  }

  .desktop-nav__cart--is-not-empty .desktop-nav__price {
    opacity: 1;
    margin-left: 7px;
    animation: 0.3s 0.3s fade backwards ease-out;
  }

  .desktop-nav__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .desktop-nav__cart--loading .desktop-nav__loader {
    opacity: 1;
  }

  .desktop-nav__cart--loading svg,
  .desktop-nav__cart--loading .desktop-nav__price {
    opacity: 0;
  }
}

@keyframes fade {
  from {
    transform: translateY(.25em);
    opacity: 0
  }
}
