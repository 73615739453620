.page-sheet {
  position: fixed;
  z-index: 82;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  padding-bottom: 140px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.page-sheet--visible {
  top: 0;
}

.page-sheet--close {
  display: none;
}

@media (min-width: 768px) {
  .page-sheet {
    padding-top: 64px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 140px;
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .page-sheet {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (min-width: 1440px) {
  .page-sheet {
    top: 87px;
    left: auto;
    right: -100%;
    margin-top: 0;
    width: 880px;
    border-left: 2px solid var(--m-black);
    padding-top: 64px;
    padding-left: 32px;
    padding-right: 45px;
    padding-bottom: 140px;
  }

  .page-sheet--visible {
    top: 87px;
    left: auto;
    right: 0;
  }

  .page-sheet--close {
    display: block;
    position: absolute;
    top: 20px;
    right: 37px;
    font-size: 0;
  }

  .page-sheet--close button {
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .page-sheet--close button:active {
    opacity: 0.7;
  }
}
