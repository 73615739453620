.form {

}

.form--login, .form--full-page {
  position: fixed;
  z-index: 80;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  padding: 0 16px;
  padding-top: 42px;
  padding-bottom: 140px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.form--full-page {
  margin-top: 0;
}

.form--login-visible, .form--full-page-visible {
  top: 0;
}

.form--register {
  padding-top: 30px;
  padding-bottom: 140px;
}

.form--login__inner {

}

.form__top-icon {
  margin-bottom: 40px;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.form__top-icon svg {
  width: 72px;
  height: 72px;
}

.form__form {
  position: relative;
  height: 100%;
}

.form__form--login {
  min-height: 500px;
}

.form__form--register {
  min-height: 1240px;
  display: flex;
  flex-direction: column;
}

.form__form--forgot {
  min-height: 260px;
}

.form__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.form__header-left {
  display: flex;
  align-items: center;
}

.form__header-left svg {
  margin-right: 16px;
}

.form__header button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: white;
}

.form__login-bottom {
  margin-top: 60px;
  width: 100%;
}

.form__submit {

}

.form__submit--login {
  margin-bottom: 16px;
}

.form__submit--success {
  display: none;
}

.form__forgot-success {
  display: none;
  margin-top: 61px;
  text-align: center;
}

.form__forgot-success--visible {
  display: block;
}

.form__submit button {
  width: 100%;
}

.form__error {
  display: flex;
  align-items: center;
  font-family: 'Geometria Regular';
  font-size: 12px;
  line-height: 20px;
  color: var(--m-red);
  height: 0;
  transition: all 0.2s ease;
}

.form__error--visible {
  height: 70px;
}

.form__error--text-center {
  justify-content: center;
}

.form__error--black-text {
  color: var(--m-black);
}

.form__create-account {
  margin-top: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.form__create-account button {
  padding: 0;
  color: var(--m-black);
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.form__forgot {
  /* margin-bottom: 32px; */
  text-align: center;
}

.form__forgot button {
  padding: 0;
  color: var(--m-black-50);
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.form__close {
  display: none;
}

.form__form .small-inputs .input-block {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-bottom: 32px;
  width: calc(50% - 8px);
}

.form__form .small-inputs .input-block:nth-child(2n) {
  margin-right: 0;
}

.form__form--register .input-block__checkbox label {
  font-size: 12px;

}

.form__form--add-address-with-map {
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  .form__form {
    width: 300px;
    margin: 0 auto;
  }
  .form__form--register {
    min-height: 1240px;
  }
  .form__form .half-width-input {
    width: 50%;
  }
  .form__form--add-address-with-map {
    width: 300px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .form--login, .form--full-page {
    padding-top: 27px;
    padding-left: 30px;
    padding-right: 30px;
    top: 87px;
    left: auto;
    right: -100%;
    margin-top: 0;
    /* width: 70%; */
    width: 880px;
    border-left: 2px solid var(--m-black);
  }

  .form--login-visible, .form--full-page-visible {
    top: 87px;
    left: auto;
    right: 0;
  }

  .form__form--add-address-with-map {
    position: static;
    margin: 0 auto;
    width: 343px;
    height: auto;
    padding-top: 118px;
  }

  /* .form::after {
    display: none;
    width: 0;
    height: 0;
  } */

  .form__form {
    position: static;
    margin: 0 auto;
    width: 343px;
    height: auto;
    padding-top: 118px;
  }

  .form__form--login,
  .form__form--register,
  .form__form--forgot,
  .form--full-page {
    min-height: auto;
  }

  .form__form--register {
    padding-top: 80px;
  }

  .form__form--forgot {
    padding-top: 200px;
  }

  .form__header--register,
  .form__header--forgot {
    position: absolute;
    top: 27px;
    left: 31px;
  }

  .form__form--add-address-with-map {
    width: 100%;
  }

  /*.form__login-bottom {*/
  /*  position: static;*/
  /*  width: 100%;*/
  /*}*/

  .form__submit--register,
  .form__submit--forgot {
    margin-top: 40px;
  }

  .form__close {
    display: block;
    position: absolute;
    top: 20px;
    right: 37px;
    font-size: 0;
  }

  .form__close button {
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}
