.dropdown__select {
    font-family: "Geometria Regular";
    font-size: 17px;
    width: 100%;
    position: relative;
}

.dropdown__select--select-hidden {
    opacity: 0;
    transition: opacity .3s;
}

.dropdown__select-dropdown {
    height: 0;
    opacity: 0;
    transition: opacity, height .1s;
    transform: translate(-200px, -30px);
}

.dropdown__select--select, .dropdown__select-dropdown--visible {
    padding: 5px 14px 3px 14px;
    background: #FFBF00;
    border-radius: 20px;
    height: 44px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    opacity: 1;
    transition: opacity .5s;
    box-sizing: border-box;
}

.dropdown__select--select {
    cursor: pointer;
}
.dropdown__select-dropdown--visible {
    box-shadow: 2px 4px 8px #ccc;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    opacity: 1;
    min-height: 44px;
    height: auto;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    transform: translate(0px, 0px);
}

.dropdown__select-dropdown--visible .dropdown__select--option {
    display: flex;
}

.dropdown__select-dropdown--visible .dropdown__select--option:nth-child(1)::after {
    content: '';
    bottom: 0;
    height: 1px;
    width: 103%;
    margin-left: -4px;
    padding: 0 -10px;
    position: absolute;
    border-bottom: 1px solid var(--m-black);
}

.dropdown__select--option {
    flex-direction: row;
    display: none;
    padding: 10px 0;
    align-content: baseline;
    justify-content: space-between;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.dropdown__select--select-icon {
    transform: rotate(0);
    transition: transform .5s;
}

.dropdown__select--select-icon-hidden {
    transform: rotate(180deg);
}

.dropdown__select--dropdown-icon {
    cursor: pointer;
    transform: rotate(0);
    transition: transform .5s;
}

.dropdown__select--dropdown-icon-visible {
    transform: rotate(180deg);
    transition: transform 1s;
}
