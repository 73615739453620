.modal {
  position: fixed;
  z-index: 73;
  top: 0;
  right: -100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 74px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal--visible {
  right: 0;
}

.modal--no-padding {
  padding-left: 0;
  padding-right: 0;
}

.modal--with-bottom-padding {
  padding-bottom: 140px;
}

.modal--moved {
  margin-top: 120px;
}

.modal__wrapper {
  position: relative;
  padding-bottom: 140px;
}

.modal__wrapper--no-padding {
  position: static;
  padding-bottom: 0;
}

.modal__submit {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  position: fixed;
  z-index: 80;
  right: -100%;
  bottom: 20px;
  transition: all 0.4s ease;
}

.modal__submit--visible {
  right: 0;
}

.modal__submit--appear-animation {
  animation: 0.4s appearModalSubmit backwards ease;
}

@keyframes appearModalSubmit {
  from {
    right: -100%;
  }
}

.modal__submit button {
  width: 100%;
}

@media (min-width: 768px) {
  .modal__submit {
    width: 50%;
    padding: 0;
    padding-left: 20px;
  }

  .modal__submit--visible {
    right: 50%;
  }

  .modal__submit--empty-cart {
    position: static;
    width: 100%;
  }
}


@media (min-width: 1440px) {
  .modal {
    padding-top: 100px;
    padding-left: 30px;
    padding-right: 30px;
    top: 87px;
    width: 880px;
    border-left: 2px solid var(--m-black);
  }

  .modal--visible {
    top: 87px;
    left: auto;
  }

  .modal--no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .modal__wrapper {
    padding-bottom: 160px;
  }

  .modal__wrapper--centered {
    margin: 0 auto;
    padding-top: 50px;
    width: 343px;
  }

  .modal__submit {
    width: 440px;
    padding-left: 16px;
    padding-right: 15px;
  }

  .modal__submit--visible {
    right: 424px;
  }

  .modal__submit--centered {
    position: static;
    margin-top: 40px;
    width: 100%;
    padding: 0;
  }

  /* .modal__submit--empty-cart {
    position: static;
    width: 100%;
  } */
}
