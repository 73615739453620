.order {

}

.order__price {
 margin-left: 10px;
 color: var(--m-black-18);
}

.order__type {
  position: relative;
  margin-bottom: 25px;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  border-bottom: 2px solid var(--m-light-grey);
  color: var(--m-black);
}

.order__type::after {
  content: '';
  display: block;
  width: 50%;
  height: 4px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: var(--m-yellow);
  transition: all 0.2s ease;
}

.order__type--right::after {
  left: 50%;
}

.order__type-courier,
.order__type-pickup {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding-top: 2px;
  padding-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.order__type-courier {

}

.order__type-pickup {

}

.order__type-courier--active,
.order__type-pickup--active {
  font-family: 'Geometria Bold';
}

.order__login {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}

.order__login-text {
  width: 50%;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
}

.order__login-btn {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  color: var(--m-black);
}

.order__login-btn span {
  margin-left: 8px;
}

.order__address {
  position: relative;
  margin-bottom: 18px;
  padding: 23px 52px 20px 52px;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.order__address::before {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--m-black);
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 16px;
}

.order__address::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 29px;
  left: 22px;
  background-color: var(--m-black);
  opacity: 0;
  transition: all 0.2s ease;
}

.order__address--active::after {
  opacity: 1;
}

.order__address-top {
  margin-bottom: 11px;
}

.order__edit-info-icon {
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  box-sizing: border-box;
  /* padding: 6px; */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--m-black);
  cursor: pointer;
}

.order__ingridients {
  margin-bottom: 20px;
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.order__edit-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.order__half-width-input {

}

.order__half-width-input .input-block {
  margin-bottom: 32px;
}

.order__small-inputs {

}

.order__small-inputs .input-block {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  margin-bottom: 32px;
  width: calc(50% - 8px);
}

.order__small-inputs .input-block:nth-child(2n) {
  margin-right: 0;
}

.order__time {
  margin-bottom: 48px;
}

.order__header-second {
  margin-bottom: 16px;
}

.order__time-wrapper {

}

.order__time-block {
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin-right: 8px;
  padding: 16px;
  padding-top: 52px;
  padding-right: 10px;
  padding-bottom: 12px;
  min-height: 104px;
  width: calc(50% - 4px);
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.order__time-block::before {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--m-black);
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 16px;
}

.order__time-block::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  left: 22px;
  background-color: var(--m-black);
  opacity: 0;
  transition: all 0.2s ease;
}

.order__time-block--active::after {
  opacity: 1;
}

.order__time-block:last-child {
  margin-right: 0;
}

.order__time-block--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.order__additional-time .input-block {
  margin-bottom: 32px;
}

.order__pickup-wrapper {
  margin-bottom: 32px;
}

.order__pickup {
  margin-bottom: 16px;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.order__pickup:last-child {
  margin-bottom: 0;
}

.order__pickup-map {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.order__pickup-map--first {
  background-image: url('https://pizzamaestrello.com/img/media/pickup-first--mobile.jpg')
}

.order__pickup-map--second {
  background-image: url('https://pizzamaestrello.com/img/media/pickup-second--mobile.jpg')
}

.order__pickup-map--third {
  background-image: url('https://pizzamaestrello.com/img/media/pickup-third--mobile.jpg')
}

.order__pickup-map--four {
  background-image: url('https://pizzamaestrello.com/img/media/pickup-four--mobile.png');
  background-position: top;
}

.order__pickup-map--five {
  background-image: url('https://pizzamaestrello.com/img/media/pickup-five--mobile.png');
  background-position: top;
}

.order__pickup-map-help-wrapper {
  display: flex;
  align-items: center;
}

.order__pickup-map-help {
  font-size: 50%;
  padding-left: 1rem;
  width: 45px;
  line-height: 14px;
}

.order__text {
  position: relative;
  box-sizing: border-box;
  padding: 17px 14px 14px 14px;
  padding-left: 52px;
  height: 58px;
  font-family: 'Geometria Medium';
  font-size: 24px;
  line-height: 30px;
  transition: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order__text::before {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--m-black);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.order__text::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  background-color: var(--m-black);
  opacity: 0;
  transition: all 0.2s ease;
}

.order__text--active::after {
  opacity: 1;
}

.order__close {
  display: none;
}

.order__zzz {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.order__zzz svg {
  margin-right: 12px;
  min-width: 28px;
  min-height: 28px;
}

.order__addresses--hidden,
.order__inputs-wrapper--hidden,
.order__pickup-wrapper--hidden,
.order--time--hidden {
  display: none;
}

.order__delivery-block {
  display: flex;
  align-items: center;
  /* padding-top: 1px; */
  padding-left: 28px;
}

.order__delivery-block svg {
  margin-right: 5px !important;
}

.order__pickup-disabled {
  filter: grayscale(1);
}

.order__pickup-disabled span {
  font-family: 'Geometria Bold';
  font-size: 60px;
  line-height: 75px;
  color: rgba(0,0,0,0.4);
  width: 100%;
  text-align: center;
  display: block;
}

@media (min-width: 768px) {
  .order__type,
  .order__half-width-input,
  .order__login,
  .order__zzz {
    width: 50%;
  }

  .order__time,
  .order__additional-time {
    width: 50%;
  }

  .order__pickup-wrapper {
    width: 50%;
  }

  .order__addresses {
    width: 50%;
  }

  .order__zzz {
    align-items: end;
  }
}

@media (min-width: 1440px) {
  .order {

  }

  /* .order__type,
  .order__half-width-input,
  .order__login {
    width: 50%;
  } */

  .order__pickup-map--first {
    background-image: url('https://pizzamaestrello.com/img/media/pickup-first--desktop.jpg')
  }

  .order__pickup-map--second {
    background-image: url('https://pizzamaestrello.com/img/media/pickup-second--desktop.jpg')
  }

  .order__pickup-map--third {
    background-image: url('https://pizzamaestrello.com/img/media/pickup-third--desktop.jpg')
  }

  .order__pickup-map--four {
    background-image: url('https://pizzamaestrello.com/img/media/pickup-four--desktop.png')
  }

  .order__pickup-map--five {
    background-image: url('https://pizzamaestrello.com/img/media/pickup-five--desktop.png')
  }

  .order__small-inputs .input-block {
    margin-right: 32px;
    width: calc(25% - 24px);
  }

  .order__small-inputs .input-block:nth-child(2n) {
    margin-right: 32px;
  }

  .order__small-inputs .input-block:nth-child(4) {
    margin-right: 0;
  }

  /* .order__time,
  .order__additional-time {
    width: 50%;
  }

  .order__pickup-wrapper {
    width: 50%;
  }

  .order__addresses {
    width: 50%;
  } */
}
