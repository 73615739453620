.burger {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  cursor: pointer;
}

.burger--opened {

}

.burger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  background: black;
  opacity: 1;
  right: 6px;
  transform: rotate(0deg);
  transition: all 0.2s ease;
}

.burger span:nth-child(1) {
  top: 9px;
}

.burger span:nth-child(2) {
  top: 17px;
  transform: rotate(-14deg);
}

.burger span:nth-child(3) {
  top: 25px;
}

.burger--opened span:nth-child(2) {
  opacity: 0;
}

.burger--opened span:nth-child(1) {
  top: 17px;
  transform: rotate(45deg);
}

.burger--opened span:nth-child(3) {
  top: 17px;
  transform: rotate(-45deg);
}
