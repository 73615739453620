:root {
  --m-yellow: #FFBF00;
  --m-yellow-50: rgba(255, 197, 53, 0.5);
  --m-black: #000000;
  --m-black-disabled: #333333;
  --m-black-50: rgba(0, 0, 0, 0.5);
  --m-black-18: rgba(0, 0, 0, 0.18);
  --m-gray: #CBCACA;
  --m-light-grey: #ECEDED;
  --m-light-grey-50: rgba(236, 237, 237, 0.5);
  --m-dark-grey: #646569;
  --m-santa-grey: #BCB8B2;
  --m-red: #EB5757;
  --m-green: #6FCF97;
  --m-green-second: #3cb46e;
  --m-blue: #6FBECF;
  --m-gray-promo: #696969;
  --m-white: #FFFFFF;
  --m-pink: #F5C7C9;

  --input-border-radius: 0;
}
