.mobile-nav {
  position: relative;
  padding: 0 16px;
  padding-bottom: 50px;
  flex-grow: 1;
}

.mobile-nav__list {
  height: 100%;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-nav__menu-item {
  /* border-bottom: 2px solid var(--m-black); */
}

.mobile-nav__menu-item--last {
  border-bottom: none;
  position: absolute;
  left: 16px;
  bottom: 20px;
  width: calc(100% - 32px);
}

.mobile-nav__menu-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0;
  width: 100%;
  border: none;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  background-color: white;
  cursor: pointer;
  color: var(--m-black);
  text-decoration: none;
  border-bottom: 2px solid var(--m-black);
}

.mobile-nav__menu-link--account {

}

.mobile-nav__menu-link--delivery {
  margin-top: 17px;
}

.mobile-nav__menu-link--lang {
  width: 32px;
}

@media (min-width: 768px) {
  .mobile-nav__list {
    width: 300px;
    margin: 0 auto;
  }

  .mobile-nav__menu-item--last {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
  }
}
