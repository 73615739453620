.edit-account {

}

.edit-account__form {

}

@media (min-width: 768px) {
  .edit-account__inputs {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .edit-account {

  }

  .edit-account__form {

  }

  .edit-account__inputs {
    width: auto;
  }
}
