.promo {
  /* position: relative;
  padding-top: 66px;
  padding-top: 81px;
  height: 154px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch; */
}

.promo::-webkit-scrollbar {
  display: none;
}

.promo__mobile-inner {
  position: relative;
  padding-top: 81px;
  padding-left: 16px;
  padding-right: 16px;
  height: 220px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.promo__mobile-inner::-webkit-scrollbar {
  display: none;
}

.promo__desktop-inner {
  display: none;
}

.promo__block {
  position: relative;
  flex: 0 0 auto;
  margin-right: 16px;
  min-width: 343px;
  width: calc(30% - 10.666666px);
  height: 100%;
}

.promo__special {
  position: relative;
  flex: 0 0 auto;
  margin-right: 16px;
  min-width: 214px;
  width: calc(40% - 10.666666px);
  height: 100%;
}

.promo__preorder {
  position: relative;
  flex: 0 0 auto;
  min-width: 214px;
  width: calc(30% - 10.666666px);
  height: 100%;
}

.promo__block-inner {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}

.promo__block-first, .promo__block-first-gray, .promo__block-santa {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s ease;
}

.promo__block-second, .promo__block-second-gray {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.promo__block-first-gray {
  background-color: var(--m-light-grey);
}

.promo__block-valentine {
  background-color: var(--m-pink)!important;
}

.promo__block-santa {
  background: #009440;
}

.promo__block-santa-bg {
  background: url('https://pizzamaestrello.com/img/media/text-santa-banner.png') center center no-repeat #009440;
  background-size: contain;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.promo__block-small-text {
  font-family: 'Geometria Medium';
  font-size: 12px;
  line-height: 14px;
  width: 120px;
  text-align: left;
}

.promo__block-small-text--mobile {
  padding-top: 35px;
  display: block;
}

.promo__block-small-text--desktop {
  display: none;
}

.promo__block-second-gray {
  background-color: var(--m-light-grey);
}

.promo__block--touched .promo__block-first,
.promo__block--touched .promo__block-first-gray {
  opacity: 0;
}

.promo__block--touched .promo__block-second,
.promo__block--touched .promo__block-second-gray {
  opacity: 1;
}

.promo__block-text-wrapper {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20px;
}
.promo__block-text {
  font-family: 'Geometria Bold';
  font-size: 27px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.03em;
  color: var(--m-gray-promo);
}

.promo__block-text-small {
  font-family: 'Geometria Narrow';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: var(--m-gray-promo);
  letter-spacing: -0.03em;
}

.promo__block-icon, .promo__block-icon-valentine,  .promo__block-icon-valentine-logo {
  bottom: -4px;
  right: 0;
  width: 270px;
  margin-left: auto;
  position: absolute;
  z-index: 1;
}

.promo__block-second .promo__block-icon {
  width: 270px;
}

.promo__block-icon-pickup {
  width: 225px;
}

.promo__block-icon-margherita {
  width: 200px;
}

.promo__block-text-margherita {
  margin-top: 1.6rem;
  width: 60%;
}

.promo__block-icon-loyalty {
  width: 225px;
  right: 10px;
  bottom: -5px;
}

.promo__block-preorder-icon {
  margin-top: 0px;
  width: 240px;
  margin-left: auto;
  margin-right: -45px;
}

.promo__block-pickup-icon {
  margin-top: -50px;
  width: 240px;
  margin-left: auto;
  margin-right: -25px;
}

.promo__block-work-time-icon {
  width: 160px;
  margin-left: auto;
  margin-right: 0px;
}

.promo__block-icon svg {
  width: 100%;
  height: auto;
}

.promo__block-icon-valentine {
  width: 200px;
  right: 20px;
  bottom: 42px;
}

.promo__block-icon-valentine svg {
  width: 100%;
  height: auto;
}

.promo__block-icon-valentine-logo {
  width: 300px;
  left: 20px;
  bottom: 10px;
  right: auto;
}

.promo__block-icon-valentine-logo svg {
  width: 100%;
  height: auto;
}

.promo__block-second-header {
  margin-bottom: 12px;
  font-family: 'Geometria Medium';
  font-size: 20px;
  line-height: 24px;
  color: var(--m-gray-promo);
}

.promo__block-second-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-gray-promo);
}



.promo__preorder-inner {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}

.promo__preorder-first {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s ease;
}

.promo__preorder-second {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.promo__preorder--touched .promo__preorder-first  {
  opacity: 0;
}

.promo__preorder--touched .promo__preorder-second {
  opacity: 1;
}

.promo__preorder-text {
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.promo__preorder-icon {
  position: absolute;
  right: 5px;
  bottom: 20px;
  width: 110px;
}

.promo__preorder-icon svg {
  width: 100%;
  height: auto;
}

.promo__block-certificate-icon {
  width: 230px;
  position: absolute;
  right: 0;
  bottom: -5px;
}

.promo__block-certificate-icon svg {
  width: 100%;
  height: auto;
}

.promo__preorder-second-header {
  margin-bottom: 12px;
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.promo__preorder-second-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.promo__block-table {
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 16px;
}

.promo__orders {
  flex: 0 0 auto;
  box-sizing: border-box;
  margin-right: 16px;
  padding: 20px;
  padding-top: 30px;
  width: 113px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  background-color: var(--m-light-grey);
}

.promo__orders-text {
  margin-top: 20px;
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.promo__special-inner {
  width: 100%;
  height: 100%;
  position: relative;
  /* transition: transform 0.5s; */
  user-select: none;
}

.promo__special-block {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 1;
  transition: 0.4s opacity ease;
}

.promo__special-block-second {
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--m-yellow);
  cursor: pointer;
  opacity: 0;
  transition: 0.4s opacity ease;
}

.promo__special--touched .promo__special-block {
  opacity: 0;
}

.promo__special--touched .promo__special-block-second {
  opacity: 1;
}

.promo__special-img {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  opacity: 1;
}

.promo__special-img--first {
  transition: opacity 0.4s ease;
}

.promo__special--touched .promo__special-img--first {
  opacity: 0;
}

.promo__special-img img {
  width: 100%;
  height: auto;
}

@media (orientation: landscape) {
  .promo__special-img {
    max-width: 230px;
  }
}

@media (min-width: 768px) {
  .promo__special-img {
    max-width: 230px;
  }

  .promo__block-text {
    font-size: 24px;
    line-height: 28px;
  }

  .promo__block-gift-icon {
    margin-top: -45px;
  }

  .promo__block-icon-pickup {
    right: -20px;
  }

  .promo__block-preorder-icon {
    margin-top: -3px;
  }

  .promo__block-pickup-icon {
    margin-right: -40px;
  }

  .promo__block-certificate-icon {
    width: 220px;
  }

  .promo__block-work-time-icon {
    margin-top: -20px;
  }
}

@media (min-width: 1094px) {
  .promo__mobile-inner {
    height: 220px;
    display: flex;
    justify-content: space-between;
  }

  .promo__block,
  .promo__special,
  .promo__preorder {
    margin-right: 16px;
    width: calc(33.333333% - 21.333333px);
  }

  .promo__block:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1170px) {
  .promo__block-work-time-icon {
    margin-top: 0;
  }
}

@media (min-width: 1440px) {
  .promo__block-small-text {
    font-size: 13px;
    width: 160px;
    padding-top: 20px;
  }
  .promo__block-small-text--desktop {
    display: block;
  }
  .promo__block-small-text--mobile {
    display: none;
  }
  .promo__block-text-margherita {
    margin-top: 2rem;
    width: 100%;
  }
}

.promo__special-info {
  padding-top: 44px;
}

.promo__special-hiddden-on-mobile {
  display: none;
}

.promo__special-hiddden-on-desktop {
  display: inline;
}

.promo__special-header {
  margin-bottom: 2px;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  color: var(--m-black);
}

.promo__special-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-50);
}

.promo__special-icon {
  display: none;
}

.promo__review {
  flex: 0 0 auto;

  /* white-space: nowrap; */
  /* display: inline-block; */
  /* vertical-align: middle; */

  box-sizing: border-box;
  width: 343px;
  height: 100%;
  padding: 20px;
  padding-right: 32px;
  margin-right: 16px;
  background-color: var(--m-yellow);
}

.promo__review:last-child {
  margin-right: 0;
}

.promo__review-inner {
  /* white-space: normal; */
  /* overflow: visible; */
}

.promo__review-ava {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-50);
}

.promo__review-ava img {
  display: none;
}

.promo__review-text {
  display: inline-block;
  margin-bottom: 14px;
  width: 100%;
  /* height: 103px; */
  height: 62px;
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
  white-space: normal;
  /* word-break: break-all; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  -ms-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.promo__review-link {
  display: flex;
  align-items: center;
}

.promo__review-link a {
  margin-right: 7px;
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: var(--m-black);
}



@media (min-width: 1440px) {
  .promo__mobile-inner {
    display: none;
    position: relative;
  }

  .promo__desktop-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 220px;
  }

  .promo__special {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
    width: calc(40% - 21.333333px);
    /* width: 496px; */
    flex: none;
  }

  .promo__preorder {
    display: inline-block;
    vertical-align: top;
    margin-right: 0;
    width: calc(30% - 21.333333px);
    /* width: 496px; */
  }

  .promo__review {
    /* display: none; */
    flex: none;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 32px;
    padding-bottom: 16px;
    /* width: 100%; */
    width: 495px;
    height: 220px;
  }

  .promo__block-first {
    padding-top: 24px;
    padding-left: 23px;
    padding-right: 0;
    text-align: left;
  }

  .promo__preorder-first {
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 25px;
    padding-right: 0;
    text-align: left;
  }

  .promo__block-text,
  .promo__preorder-text {
    font-family: 'Geometria Bold';
    font-size: 24px;
    line-height: 28px;
  }

  .promo__block-icon {
    bottom: -7px;
  }

  .promo__block-icon-loyalty {
    bottom: -8px;
  }

  .promo__block-second-header,
  .promo__preorder-second-header {
    font-family: 'Geometria Bold';
    font-size: 24px;
    line-height: 28px;
  }

  .promo__orders {
    margin-right: 32px;
    padding: 0;
    padding-top: 53px;
    width: calc(24% - 21.333333px);
  }

  .promo__orders svg {
    width: 65px;
    height: auto;
  }

  .promo__orders-text {
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
  }

  .promo__special-block {
    padding: 24px;
    text-align: left;
    background-color: var(--m-light-grey);
  }

  .promo__special-block-second {
    padding: 24px;
    background-color: var(--m-light-grey);
  }

  .promo__special-info {

  }

  .promo__special-hiddden-on-mobile {
    display: inline;
  }

  .promo__special-hiddden-on-desktop {
    display: none;
  }

  .promo__special-img {
    top: 50%;
    left: auto;
    right: -40px;
    transform: none;
    transform: translateY(-50%);
    width: 280px;
    max-width: none;
  }

  .promo__special-img img {

  }

  .promo__special-info {
    padding-top: 0;
  }

  .promo__special-header {
    margin-bottom: 12px;
  }

  .promo__special-text {
    width: 250px;
  }

  .promo__block-second-text--special {
    color: var(--m-black-50);
  }

  .promo__special-icon {
    display: block;
    position: absolute;
    left: 24px;
    bottom: 24px;
    margin-left: -20px;
  }

  .promo__reviews-slider-button {
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    line-height: 0;
    opacity: 0;
    transition: 0.3s all ease;
  }

  .promo__reviews-slider-button:active {
    right: -30px;
    opacity: 0.5;
  }

  .promo__review-inner {

  }

  .promo__review-ava {
    margin-bottom: 12px;
  }

  .promo__review-ava img {
    margin-right: 8px;
    display: inline-block;
    width: 32px;
    height: 32px;
  }

  .promo__review-text {
    margin-bottom: 18px;
    height: 99px;
    font-family: 'Geometria Regular';
  }

  .promo__review-link {

  }

  .promo__review-link a {

  }

  .promo__all-reviews {
    width: 320px;
  }

  .promo__block-pickup-icon {
    margin-top: -55px;
    margin-right: -25px;
  }

  .promo__block-work-time-icon {
    width: 160px;
    margin-left: auto;
    margin-right: 20px;
    margin-top: -15px;
  }
}
