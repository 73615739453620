.card {
  /* margin-left: 26px;
  margin-right: 16px; */
  margin-bottom: 44px;
  scroll-margin-top: 120px;
  scroll-snap-margin-top: 120px;
}

.card__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 26px;
  margin-right: 16px;
}

.card__top--desktop {
  display: none;
}

.card__name {
  margin: 0;
  width: 75%;
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 1.2;
}

.card__price {
  width: 25%;
  text-align: right;
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 1.2;
}

.card__separator {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Geometria Narrow Regular';
  color: var(--m-black-50);
}

.card__nameru {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Geometria Narrow Regular';
  color: var(--m-black-50);
}

.card__info {
  padding-top: 14px;
  /* padding-left: 26px; */
  /* margin-right: 16px; */
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
  -ms-overflow-style: none;
}

.card__info::-webkit-scrollbar {
  display: none;
}

.card__left {
  white-space: nowrap;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 26px;
  margin-right: 16px;
  width: 87%;
  height: 100%;
  background-color: white;
}

.card__right {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  margin-right: 16px;
}

.card__bg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.card__out-of-stock {
  left: 50%;
  top: 50%;
  display: none;
  position: absolute;
  flex-wrap: wrap;
  width: 170px;
  justify-content: center;
  z-index: 11;
  margin-left: -85px;
  margin-top: -43px;
}

.card__out-of-stock span {
  background-color: var(--m-black);
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 102px;
  margin: 0;
  color: white;
  text-align: center;
  display: flex;
}

.card__out-of-stock--visible {
  display: flex;
}

.card__left--selected .pizza-bg-part {
  fill: var(--m-yellow);
}

.card__content-img {
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 10;
  display: block;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card__add {
  position: absolute;
  z-index: 15;
  right: 16px;
  bottom: 16px;
  padding: 0;
  width: 44px;
  height: 44px;
  background-color: var(--m-black);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.card__add-in-cart {
  display: none;
}

.card__add--activated {
  width: 120px;
  border-radius: 50px;
}

.card__add-minus,
.card__add-plus {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--m-black);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card__add-plus {
  right: 0;
}

.card__add-minus {
  left: 0;
  display: none;
  opacity: 0;
}

.card__add-minus svg {
  display: none;
}

.card__add-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Geometria Narrow Bold';
  font-size: 24px;
  line-height: 30px;
  color: white;
  opacity: 0;
  transition: all 0.2s ease;
}

.card__add-amount--visible {
  opacity: 1;
  animation: 0.3s 0.3s appear backwards ease;
}

.card__add-minus--visible {
  display: flex;
  opacity: 1;
  animation: 0.3s 0.3s appear backwards ease;
}

.card__add-minus--visible svg {
  display: inline;
}

.card__add-plus:active,
.card__add-minus:active {
  transform: scale(0.95);
  opacity: 0.5;
}

@keyframes appear {
  from {
    display: none;
    opacity: 0;
  }
}

@keyframes disappear {
  from {
    display: none;
    opacity: 0;
  }
}

.card__add-plus {

}

.card__ingridients {
  margin-bottom: 15px;
  white-space: normal;
  color: var(--m-black-50);
}

.card__addition {
  color: var(--m-black-50);
}

.card__feature {
  position: absolute;
  z-index: 19;
  top: -14px;
  left: 25px;
  padding: 6px 12px;
  border-radius: 102px;
  color: white;
  background-color: var(--m-black);
  font-family: 'Geometria Narrow Regular';
  font-size: 16px;
  line-height: 20px;
}

.card__feature--spicy{
  background-color: var(--m-red);
}

.card__feature--sold-out {
  background-color: var(--m-red) !important;
}

.card__feature--vegetarian {
  background-color: var(--m-green);
}

.card__feature--vegan {
  background-color: var(--m-green-second);
}

.card__feature--fish {
  background-color: var(--m-blue);
}

@media (min-width: 768px) {
  .card {
    margin: 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 30px;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 24px);
  }

  .card:nth-child(2n) {
    margin-left: 0;
  }

  .card__info {
    /* padding-top: 36px; */
    white-space: normal;
    overflow-x: auto;
    overflow-y: visible;
  }

  .card__left {
    white-space: normal;
    display: block;
    padding-left: 0;
    width: 100%;
    margin-right: 0;
  }

  .card__right {
    white-space: normal;
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .card__top {
    display: none;
    margin-top: 20px;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
  }

  .card__top--desktop {
    display: flex;
  }

  .card__content-img {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card__addition {
    font-size: 13px;
  }

  .card__add {
    right: 25px;
    bottom: 25px;
  }

  .card__feature {
    left: 0;
  }
}

@media (min-width: 1024px) {
  .card {
    width: calc(33.333333% - 21.333333px);
  }

  .card:nth-child(2n) {
    margin-left: 0;
    margin-right: 16px;
  }

  .card:nth-child(3n) {
    margin-left: 0;
  }

  .card:nth-child(3n + 1) {
    margin-left: 16px;
  }

  .card:nth-child(3n + 2) {
    margin-left: 0;
  }
}

@media (min-width: 1440px) {
  .card {
    margin: 0;
    margin-right: 32px;
    margin-bottom: 40px;
    width: calc(33.333333% - 21.333333px);
  }

  .card:nth-child(2n) {
    margin-left: 0;
    margin-right: 32px;
  }

  .card:nth-child(3n) {
    margin-left: 0;
    margin-right: 0;
  }

  .card:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: 32px;
  }

  .card:nth-child(3n + 2) {
    margin-left: 0;
  }

  .card__info {
    padding-top: 20px;
  }

  .card__left:hover .card__content-img {
    transform: scale(1.05);
  }

  .card__left:hover .card__add {
    width: 148px;
    border-radius: 50px;
  }

  .card__left:hover .card__add--activated {
    width: 120px;
  }

  .card__add:active {
    opacity: 0.5;
  }

  .card__add--activated:active {
    opacity: 1;
  }

  .card__add:hover {
    background-color: var(--m-dark-grey);
  }

  .card__add:hover .card__add-plus {
    opacity: 1;
    background-color: var(--m-dark-grey);
  }

  .card__add--activated:hover {
    background-color: var(--m-black);
  }

  .card__add--activated:hover .card__add-plus {
    background-color: var(--m-black);
  }

  .card__add--activated .card__add-plus:hover,
  .card__add--activated .card__add-minus:hover {
    opacity: 0.5;
  }

  .card__add-in-cart {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    font-family: 'Geometria Narrow Bold';
    font-size: 16px;
    line-height: 30px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .card__left:hover .card__add-in-cart {
    display: inline-block;
    animation: 0.3s 0.1s appear backwards ease;
  }

  .card__left:hover .card__add--activated .card__add-in-cart {
    display: none;
  }

  .card__add--activated .card__add-in-cart {
    display: none;
    animation: 0.3s disappear backwards ease;
  }

  /* .card__empty-img {
    padding-left: 28.69px;
    padding-right: 33.51px;
  } */

  .card__content-img {
    top: -14px;
    /* padding-left: 28.69px; */
    /* padding-right: 33.51px; */
  }

  .card__add {
    right: 24px;
    bottom: 24px;
  }
}
