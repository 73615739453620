.cart-link, .cart-link-loyalty  {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  border: none;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  color: white;
  text-decoration: none;
  background-color: var(--m-black);
  cursor: pointer;
  transition: all 0.2s ease;
}

.cart-link-loyalty {
  background-color: var(--m-yellow);
}

.cart-link-loyalty  .cart-link__text {
  font-size: 20px;
  color: var(--m-black);
}


.cart-link:active {
  transform: scale(0.95);
}

.cart-link--outside, .cart-link--outside-loyalty {
  position: fixed;
  z-index: 82;
  bottom: -100%;
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
}

.cart-link--outside-loyalty {
  z-index: 73;
}

.cart-link--outside-visible {
  bottom: 20px;
}

.cart-link__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.cart-link--loading .cart-link__loader {
  opacity: 1;
}

.cart-link__icon,
.cart-link__text,
.cart-link__price {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.cart-link--loading .cart-link__icon,
.cart-link--loading .cart-link__text,
.cart-link--loading .cart-link__price {
  opacity: 0;
}

@media (orientation: landscape) {
  .cart-link--outside, .cart-link--outside-loyalty {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0;
  }

  .cart-link--outside:active, .cart-link--outside-loyalty.active  {
    transform: translateX(-50%) scale(0.95);
  }

  .cart-link--outside-visible {
    /* bottom: 20px; */
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .cart-link--outside, .cart-link--outside-loyalty {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
    margin-right: 0;
  }

  .cart-link--outside:active {
    transform: translateX(-50%) scale(0.95);
  }

  .cart-link--outside-visible {
    /* bottom: 14px; */
  }
}

@media (min-width: 1440px) {
  .cart-link--outside-visible {
    bottom: -100%;
  }
}

.cart-link__icon {
  position: absolute;
  top: 14px;
  left: 15px;
  width: 32px;
  height: 32px;
}

.cart-link__price {
  position: absolute;
  top: 23px;
  right: 15px;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
}

@media (hover: hover) and (pointer: fine) {
  .cart-link:hover {
    background-color: var(--m-dark-grey);
  }
}
