.santa-page {
  padding-top: 20px;
}

.santa-page__banner {
  height: 380px;
  background: #009440;
  padding: 0 20px;
  margin-bottom: 20px;
}

.santa-page__banner--bg {
  background: url('https://pizzamaestrello.com/img/media/text-santa-page.png') center center no-repeat #009440;
  background-size: contain;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.santa-page__banner-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.santa-page__banner-column .base-text {
  padding-top: 40px;
  font-size: 20px;
  line-height: 24px;
  width: 80%;
}

.santa-page__container {
  padding: 0 20px;
}

.santa-page__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

[class*="santa-page__counter-item-"] {
  width: 63px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.santa-page__counter-item-one {
  background: url('/src/svg/santa-counter-one.svg') center center no-repeat;
}

.santa-page__counter-item-two {
  background: url('/src/svg/santa-counter-two.svg') center center no-repeat;
}

.santa-page__counter-item-three {
  background: url('/src/svg/santa-counter-three.svg') center center no-repeat;
}

.santa-page__counter-title {
  text-align: center;
  font-size: 28px;
  text-transform: lowercase;
  margin: 20px 0;
}

.santa-page__text-fullwidth {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 24px;
}

.santa-page__guide {
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
}

.santa-page__guide-item {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .santa-page__banner {
    margin-bottom: 40px;
  }

  .santa-page__container {
    padding: 0;
  }

  .santa-page__banner-column .base-text {
    padding-top: 40px;
    font-size: 20px;
    line-height: 24px;
    width: 60%;
  }
}

@media (min-width: 1440px) {
  .santa-page__banner {
    margin-bottom: 40px;
  }

  .santa-page__container {
    padding: 0 45px;
  }

  .santa-page__text-fullwidth {
    font-size: 16px;
    line-height: 24px;
  }
}
