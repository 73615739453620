@font-face {
  font-family: 'Geometria Bold';
  src: local('Geometria Bold'), local('Geometria-Bold'),
       url('../fonts/Geometria-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Regular';
  src: local('Geometria Regular'), local('Geometria-Regular'),
       url('../fonts/Geometria.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Medium';
  src: local('Geometria Medium'), local('Geometria-Medium'),
       url('../fonts/Geometria-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Heavy';
  src: local('Geometria Heavy'), local('Geometria-Heavy'),
       url('../fonts/Geometria-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Narrow';
  src: local('Geometria Narrow'), local('Geometria-Narrow'),
       url('../fonts/Geometria-Narrow.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Narrow Regular';
  src: local('Geometria Narrow Regular'), local('Geometria-Narrow-Regular'),
       url('../fonts/Geometria-Narrow.woff') format('woff');
}

@font-face {
  font-family: 'Geometria Narrow Bold';
  src: local('Geometria Narrow Bold'), local('Geometria-Narrow-Bold'),
       url('../fonts/Geometria-NarrowBold.woff') format('woff');
}

html,
body {
  color: var(--m-black);
  background-color: white;
}

.root {

}

.no-flick svg {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0 ,0);
}

.base-header {
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  color: var(--m-black);
}

.base-header-grey {
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  color: var(--m-black-50);
}

.base-subheader {
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.base-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
  text-decoration: none;
}

.base-text-white {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-decoration: none;
}

.base-text-grey {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black-50);
  text-decoration: none;
}

.base-text-red {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-red);
  text-decoration: none;
}

.base-text-bold {
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
  text-decoration: none;
}

.base-text-bold-white {
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-decoration: none;
}

.base-text-with-place {
  padding: 6px 8px;
  background-color: #292929;
}

.small-text-grey {
  font-family: 'Geometria Bold';
  font-size: 13px;
  line-height: 15.6px;
  color: var(--m-black-50);
  text-decoration: none;
}

.small-text-red {
  font-family: 'Geometria Bold';
  font-size: 13px;
  line-height: 15.6px;
  color: var(--m-red);
  text-decoration: none;
}

.pizza-bg-part,
.dish-bg-part {
  transition: all 0.2s ease;
}

.app {
  /* padding-top: 66px; */
}

.app__form-bg {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 70;
  top: 100%;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s ease;
}

.app__form-bg--visible-dish-in-cart {
  z-index: 79;
}

.app__form-bg--visible-login-in-payment {
  z-index: 79;
}

.app__form-bg--visible {
  top: 0;
  opacity: 1;
}

.app__form-bg-line {
  display: none;
}

@media (min-width: 1440px) {
  .app {
    padding-top: 119.33px;
  }

  .app__form-bg {
    background: linear-gradient(-270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  }

  .app__form-bg--visible {
    top: 85px;
  }

  .app__form-bg-line {
    display: block;
    width: 50%;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--m-black);
    transition: all 0.4s ease;
  }
}

.effect {
  transition: all 0.2s ease;
}

.effect:active {
  transform: scale(0.9);
}

.effect2 {
  transition: all 0.2s ease;
}

.effect2:active {
  transform: scale(0.95);
}

.effect3 {
  transition: all 0.2s ease;
}

.effect3:active {
  opacity: 0.5;
}

.effect4 {
  transition: all 0.2s ease;
}

.effect4:active {
  transform: scale(0.95);
}

.effect5 {
  transition: all 0.2s ease;
}

.effect5:active {
  background-color: var(--m-light-grey-50);
}

@media (hover: hover) and (pointer: fine) {
  .effect:hover {
    opacity: 0.5;
  }

  .effect2:hover {
    background-color: var(--m-dark-grey);
  }

  .effect3:hover {
    opacity: 0.5;
  }

  .effect4:hover {
    background-color: var(--m-light-grey-50);
  }

  .effect5:hover {
    background-color: var(--m-light-grey-50);
  }
}

.payment-form {
  display: none;
}

.not-visible {
  visibility: hidden;
}

.text-line-through {
  position: relative;
  display: block;
}

.text-line-through::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--m-yellow);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.link {
  cursor: pointer;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 8px;
}

.pr-2 {
  padding-right: 16px;
}

.is-block {
  display: block;
}

.is-column {
  flex-direction: column;
}

.is-row {
  flex-direction: row;
}

.is-left-aligned {
  align-items: flex-start !important;
}

.icon-wrapper-32 {
  height: 32px;
}

.invisible {
  display: none;
}

.hidden-mobile {
  display: none;
}

.hidden-tablet {
  display: block;
}

.flex-block-vertical {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.flex-block-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-content-space-between {
  justify-content: space-between;
}

@media (min-width: 767px) {
  .hidden-mobile {
    display: block;
  }
  .hidden-tablet {
    display: none;
  }
}

@media (max-width: 767px) {
  .mt-2-mobile {
    margin-top: 16px;
  }
}

/* .lazyload-placeholder {
  height: 172px;
}

@media (min-width: 375px) {
  .lazyload-placeholder {
    height: 205px;
  }
}

@media (min-width: 768px) {
  .lazyload-placeholder {
    height: 255px;
  }
}

@media (min-width: 1024px) {
  .lazyload-placeholder {
    height: 226px;
  }
}

@media (min-width: 1440px) {
  .lazyload-placeholder {
    height: 268px;
  }
} */
