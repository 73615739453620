.home {
  
}

@media (min-width: 1440px) {
  .home {
    position: relative;
    /* margin-left: 32px; */
    margin-right: 32px;
    padding-left: 208px;
  }
}
