.levels__block--row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 8px;
    box-sizing: border-box;
    align-items: stretch;
}

.levels__block--row .levels__block--item:last-child {
    margin-right: 0;
}

.levels__block--item {
    width: 276px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 0 auto;
    margin-right: 16px;
    background-color: var(--m-white);
    box-sizing: border-box;
    padding: 14px 20px;
}

.levels__block--item-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 44px;
}

.levels__block--item-label {
    background-color: var(--m-yellow);
    padding: 3px 10px 2px 10px;
    height: 27px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    box-sizing: border-box;
}

.levels__block--item-status {

}

.levels__block--item-head-text {
    font-size: 21px;
    font-family: "Geometria Medium";
    margin-top: 32px;
}

.levels__block--item-bonus, .levels__block--item-bonus-plus {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.levels__block--item-bonus-icon {
    margin-right: 16px;
}

.levels__block--item-bonus-plus {
    margin-top: 0;

}

.levels__block--item-bonus-info {
    font-size: 10px;
    line-height: 12px;
}

@media (min-width: 768px) {
    .levels__block--item {
        width: 32.5%;
        min-width: 261px;
    }
}

@media (min-width: 1440px) {
    .levels__block--item {
        width: 261px;
    }
}
