.all-orders {
  position: fixed;
  z-index: 70;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  padding-top: 12px;
  padding-bottom: 140px;
  background-color: white;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.all-orders--visible {
  top: 0;
}

.all-orders__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.all-orders__header-left {
  display: flex;
  align-items: center;
}

.all-orders__header-left svg {
  margin-right: 16px;
}

.all-orders__header button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: white;
}

.all-orders__close {
  display: none;
}


@media (min-width: 1440px) {
  .all-orders {
    padding-top: 27px;
    padding-left: 30px;
    padding-right: 30px;
    top: 87px;
    left: auto;
    right: -100%;
    /* width: 70%; */
    width: 880px;
    border-left: 2px solid var(--m-black);
  }

  .all-orders--visible {
    top: 87px;
    left: auto;
    right: 0;
  }

  .all-orders__mobile-close {
    display: none;
  }

  .all-orders__close {
    display: block;
    position: absolute;
    top: 20px;
    right: 37px;
    font-size: 0;
  }

  .all-orders__close button {
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}
