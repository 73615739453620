.order-created {
  position: fixed;
  z-index: 71;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  padding: 0 16px;
  padding-top: 42px;
  padding-bottom: 140px;
  background-color: white;
  text-align: center;
  transition: all 0.4s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.order-created--visible {
  top: 0;
}

.order-created__top-icon {
  margin-bottom: 40px;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.order-created__top-icon svg {
  width: 72px;
  height: 72px;
}

.order-created__header {
  margin-bottom: 12px;
}

.order-created__buttons {
  margin-top: 50px;
}

.order-created__buttons button,
.order-created__pay-button button {
  width: 100%;
}

.order-created__pay-button {
  margin: 20px 0;
}

@media (min-width: 1440px) {
  .order-created {
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 407px;
    height: 675px;
    margin: 0;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }

  .order-created--visible {
    top: 50%;
  }

  .order-created--user {
    height: 400px;
  }

  .order-created::after {
    display: none;
  }

  .order-created__top-icon svg {
    width: 120px;
    height: 120px;
  }
}
