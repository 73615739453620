.delivery {
  padding-top: 20px;
}

.delivery__map {
  width: 100%;
  height: 500px;
}

.delivery__map-container {

}

.delivery__banner {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  margin-bottom: 30px;
  background-color: var(--m-yellow);
}

.delivery__banner-column {
  display: flex;
}

.delivery__banner-header {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.delivery__banner-header svg {
  width: 100%;
}

.delivery__banner-bottom-text {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 0 10px;
}

.delivery__container {
  padding: 0 20px;
}

.delivery__container-search-input {
  padding: 0px 0 40px;
  position: relative;
}

.delivery__text-fullwidth {
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 24px;
}

.delivery__guide {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.delivery__bottom-text-left {
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
}

.delivery__banner-result {
  font-family: 'Geometria Regular';
  font-size: 16px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
}

.delivery__banner-result-appear {
  animation: 0.3s disappear backwards ease;
}

@media (min-width: 768px) {
  .delivery__banner {
    /*padding: 70px 88px 55px 45px;*/
    margin-bottom: 30px;
  }

  .delivery__banner-column {
    margin: 20px 0 30px;
  }

  .delivery__banner-bottom-text {
    padding: 0 60px;
  }

  .delivery__container {
    padding: 0;
  }

  .delivery__guide {
    min-height: 360px;
    margin-top: 64px;
  }

  .delivery__guide-right-column {
    padding-left: 30px;
  }

  .delivery__guide-stage {
    margin-bottom: 0;
  }

  .delivery__bottom-text-left {
    width: 600px;
    margin-top: 60px;
  }

  .delivery__container-search-input {
    padding: 0px 0 40px;
  }
}

@media (min-width: 1440px) {
  .delivery__banner {
    padding: 25px 88px 40px 88px;
    margin-bottom: 20px;
  }

  .delivery__banner-header svg {
    width: 440px;
  }

  .delivery__container {
    padding: 0 45px;
  }

  .delivery__text-fullwidth {
    font-size: 16px;
    line-height: 24px;
  }

  .delivery__guide {
    min-height: 400px;
    margin-top: 84px;
  }

  .delivery__guide-left-column,
  .delivery__guide-right-column {
    width: 300px;
  }

  .delivery__bottom-text-center {
    width: 500px;
    margin: 0 auto;
    margin-top: 84px;
    text-align: center;
  }

  .delivery__container-search-input {
    /*padding: 20px 140px 45px;*/
  }
}