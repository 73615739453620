.not-found {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

.not-found__inner {

}

.not-found__icon {
  margin-bottom: 46px;
}

.not-found__text {
  margin-bottom: 32px;
  color: var(--m-black);
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
}

.not-found__link {

}

.not-found__link a {
  color: var(--m-black);
  font-family: 'Geometria Medium';
  font-size: 24px;
  line-height: 30px;
  text-decoration: none;
  transition: all 0.3s;
}

.not-found__link a:active {
  opacity: 0.7;
}
