.edit-address {
  z-index: 80;
}

.edit-address__form {

}

.edit-address__big-input {

}

.edit-address__big-input--with-margin {
  margin-bottom: 32px;
}

.edit-address__small-input {

}

.edit-address__small-input .input-block {
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 8px);
}

.edit-address__small-input .input-block:nth-child(2n) {
  margin-right: 0;
}

.edit-address__delete {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.edit-address__delete--mobile {
  margin-top: 30px;
}

.edit-address__delete--desktop {
  display: none;
}

.edit-address__delete span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  box-sizing: border-box;
  padding: 6px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--m-light-grey);
}

@media (min-width: 768px) {
  .edit-address__inputs {
    width: 50%;
  }

  .edit-address__delete--mobile {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .edit-address {

  }

  .edit-address__form {

  }

  .edit-address__big-input {

  }

  .edit-address__small-input {

  }

  .edit-address__big-input .input-block {
    width: calc(50% - 16px);
  }

  .edit-address__small-input .input-block {
    margin-right: 32px;
    width: calc(25% - 24px);
  }

  .edit-address__small-input .input-block:nth-child(2n) {
    margin-right: 32px;
  }

  .edit-address__small-input .input-block:nth-child(4) {
    margin-right: 0;
  }

  .edit-address__delete--mobile {
    display: none;
  }

  .edit-address__delete--desktop {
    display: flex;
    position: absolute;
    top: 17px;
    right: -145px;
  }

  .edit-address__inputs {
    width: auto;
  }

  .edit-address__delete--mobile {
    width: auto;
  }
}
