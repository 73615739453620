.cart {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 38px;
  background: #F0F0F0;
}

.cart .modal__wrapper {
  display: flex;
  flex-direction: column;
}

.cart__col {
  display: flex;
  flex-direction: column;
}

.cart__col-item--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cart__col--text-header {
  font-family: 'Geometria Bold';
  font-size: 22px;
  line-height: 24px;
  color: var(--m-black);
}

.cart__col-item--center {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.cart__col-item {
  display: flex;
  flex-direction: column;
  background-color: var(--m-white);
  padding: 20px;
  box-sizing: border-box;
}

.cart__col-item-animated {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .2s;
}

.cart__col-item-visible {
  height: auto;
  opacity: 1;
}

.cart__col-item-full {
  width: 100%;
}

.cart__col-item-half {
  width: 49.9%;
}

.cart__col-item-two-thirds {
  width: 66.6%;
}

.cart__col-item-one-third {
  width: 33.3%;
}

.cart__col--button button,
.cart__col--button-tablet button,
.cart__col--button-mobile button {
  border: none;
  display: flex;
  background-color: transparent;
}

.cart__col--button-mobile svg {
  margin-right: -14px;
}

.cart__col--button, .cart__col--button-tablet {
  display: none;
}

.cart .modal-header, .modal-header button {
  background: inherit;
}

.cart .modal-header {
  padding-left: 15px;
  padding-right: 15px;
}

.cart__wrapper {
  box-sizing: border-box;
  padding-top: 50px;
  text-align: center;
}

.cart__left {
  padding: 0 16px;
}

.cart__empty-icon {
  margin: 24px 0;
  display: flex;
  justify-content: center;
}

.cart__empty {
  padding: 0 35px;
  margin-bottom: 20px;
}

.cart__list {

}

.cart__item {
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--m-black);
}

.cart__top {
  display: flex;
  align-items: flex-start;
  margin-bottom: 27px;
}

.cart__item-img, .cart__item-img-alt {
  align-items: center;
  background-color: var(--m-yellow);
  border-radius: 0;
  display: flex;
  height: 64px;
  margin-right: 10px;
  width: 64px;
  overflow: hidden;
}

.cart__item-img img {
  width: 100%;
}

.cart__item-img-alt img {
  width: 200%;
  margin-top: -48px;
  margin-left: -29px;
}

.cart__bg-img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.cart__info {
  flex-grow: 1;
}

.cart__name {
  margin-bottom: 9px;
}

.cart__name-en,
.cart__name-ru {
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.cart__name-ru {
  color: var(--m-black-50);
}

.cart__price-block {
  display: flex;
  align-items: center;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;

}

.cart__price-block span {
  display: flex;
  margin-bottom: -4px;
}

.cart__amount-block {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: 'Geometria Bold';
  font-size: 24px;
  line-height: 30px;
}

.cart__amount-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 21px;
  height: 21px;
  border: none;
  border-radius: 50%;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.cart__amount-btn svg {
  fill: var(--m-black);
}

.cart__amount-value {
  width: 46px;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
}

.cart__ingridients {
  font-family: 'Geometria Medium';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.cart__ingridients--added {
  align-items: flex-start;
  color: var(--m-black-50);
}

.cart__edit-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cart__ingridients--added .cart__edit-btn {
  align-items: center;
}

.cart__edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  box-sizing: border-box;
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--m-light-grey);
  cursor: pointer;
}

.cart__edit-icon svg {
  width: 16px;
  height: 16px;
}

.cart__cutlery {
  margin-top: 25px;
  margin-bottom: 27px;
  display: flex;
  align-items: center;
}

.cart__cutlery-text {
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.cart__gift {
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
  margin: -20px;
}

.cart__gift-left {
  padding: 20px ;
  width: 50%;
  box-sizing: border-box;
}

.cart__gift-left .base-header {
  font-size: 22px;
  line-height: 24px;
}

.cart__gift-right {
  background-color: var(--m-yellow);
  box-sizing: border-box;
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 210px;
}

.cart__gift-right img {
  width: 160%;
}

.cart__gift--active {
  background-color: var(--m-green);
}

.cart__gift-header {
  margin-bottom: 12px;
}

.cart__gift-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  width: 115px;
  height: 100%;
  display: flex;
  align-items: center;
}

.cart__gift-img img {
  width: 100%;
}

@media (min-width: 375px) {
  .cart__gift-img {
    right: -16px;
    width: 147px;
  }
  .cart__empty {
    text-align: center;
  }
  .modal__submit--empty-cart {
    margin: 0 auto;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .cart__gift-img {
    right: -16px;
    width: 200px;
  }
  .cart {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 68px;
  }

  .cart__col--button-tablet {
    display: block;
    position: absolute;
    top: -55px;
    right: -10px;
  }

  .cart__row-tablet {
    display: flex;
    flex-direction: row;
  }

  .cart__row-tablet > .cart__col {
    width: 50%;
  }

  .cart__row-tablet > .cart__col:last-child {
    margin-left: 20px;
    width: calc(50% - 20px);
  }

  .cart__col--button-mobile {
    display: none;
  }

  .cart__col-item-full-tablet {
    width: 100%;
  }

  .cart__col-item-half-tablet  {
    width: 49.9%;
  }

  .cart__col-item-two-thirds-tablet  {
    width: 66.6%;
  }

  .cart__col-item-one-third-tablet  {
    width: 33.3%;
  }

  .cart__col-item-auto-tablet {
    width: auto;
  }

  .cart .modal__submit {
    width: calc(50% - 7px)
  }

  .cart .modal__submit--visible {
    right: calc(50% + 7px)
  }
}

@media (min-width: 1440px) {
  .cart__col--button-tablet {
    display: none;
  }

  .cart__col--button {
    display: block;
    position: absolute;
    top: -58px;
    right: -10px;
  }

  .cart__col-item-full-desktop {
    width: 100%;
  }

  .cart__col-item-half-desktop {
    width: 49.9%;
  }

  .cart__col-item-two-thirds-desktop  {
    width: 66.6%;
  }

  .cart__col-item-one-third-desktop  {
    width: 33.3%;
  }

  .cart .modal__submit {
    width: 442px;
  }

  .cart .modal__submit--visible {
    right: 432px;
  }
}

.cart__extra-valentine .cart__addition-top {
  background-color: var(--m-pink);
}

.cart__extra-valentine .cart__addition-img img {
  width: 100%;
  margin: 0 0 -40px;
}

.cart__extra {
  padding-right: 0;
}

.cart__extra-list {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
}

.cart__extra-list::-webkit-scrollbar {
  display: none;
}

.cart__extra-header {
  margin-bottom: 16px;
  padding: 0 16px;
}

.cart__addition {
  position: relative;
  white-space: nowrap;
  margin-right: 33px;
  display: inline-block;
  vertical-align: top;
  width: 200px;
  box-sizing: border-box;
  cursor: pointer;
}

.cart__addition .cart__addition-img {
  /*opacity: 1;*/
}

.cart__gift-addition {
  position: relative;
  white-space: nowrap;
  margin-right: 33px;
  display: inline-block;
  vertical-align: top;
  width: 100px;
  box-sizing: border-box;
  cursor: pointer;
  transition: opacity 0.3s;
}

.cart__addition:last-child, .cart__gift-addition:last-child {
  margin-right: 0;
}

.cart__addition-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.cart__addition--selected .dish-bg-part {
  fill: var(--m-yellow);
}

.cart__addition-top {
  position: relative;
  z-index: 5;
  display: flex;
  background-color: var(--m-yellow);
  height: 138px;
  align-items: center;
  overflow: hidden;
}

.cart__gift-addition-top {
  position: relative;
  z-index: 5;
  display: flex;
  background-color: var(--m-yellow);
  height: 110px;
  align-items: center;
  overflow: hidden;
}

.cart__addition-img {
}

.cart__addition-img img {
  width: 160%;
  margin-left: -96px;
  margin-top: -118px;
}

.cart__gift-addition-img img {
  width: 100%;
}

.cart__gift-addition-img-alt img {
  width: 200%;
  margin-left: -51px;
  margin-top: -58px;
}

.cart__addition-check {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s;
}

.cart__addition-check--visible {
  opacity: 1;
}

.cart__addition-name, .cart__gift-addition-name {
  position: relative;
  z-index: 5;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 48px;
  white-space: normal;
  font-family: 'Geometria Bold';
  font-size: 16px;
  line-height: 18px;
  color: var(--m-black);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cart__gift-addition-name {
  word-wrap: normal;
  font-family: 'Geometria Medium';
}
.cart__addition-name span:last-child {
  white-space: nowrap;
}

.cart__addition-params, .cart__gift-addition-params {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
}

.cart__addition-weight, .cart__gift-addition-weight {
  font-family: 'Geometria Regular';
  color: var(--m-black-50);
}

.cart__addition-price {
  color: var(--m-black);
}

.cart__gift-addition-check {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  right: 0;
  bottom: 0;
}

.cart__gift-addition-check--visible {
  opacity: 1;
}

.cart__addition--disabled:not(.cart__addition--selected) {
  opacity: 0.2;
}

@media (min-width: 768px) {

  .cart__wrapper {
    margin: 0 auto;
    padding-top: 70px;
    width: 450px;
  }

  .cart__left {
    display: inline-block;
    vertical-align: top;
    /* padding-left: 30px; */
    padding-right: 0;
    box-sizing: border-box;
    width: calc(50% - 16px);
    margin-right: 32px;
  }

  .cart__right {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
  }

  .cart__addition {
    padding: 0;
  }

  .cart__addition-img img {
    width: 160%;
    margin-left: -96px;
    margin-top: -118px;
  }
}

@media (min-width: 1440px) {
  .cart {

  }

  .cart__wrapper {
    margin: 0 auto;
    padding-top: 70px;
    width: 450px;
  }

  .cart__empty {
    margin-bottom: 50px;
  }

  .cart__left {
    padding-left: 30px;
  }

  /* .cart__left {
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
    padding-right: 0;
    box-sizing: border-box;
    width: calc(50% - 16px);
    margin-right: 32px;
  }

  .cart__right {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
  } */

  .cart__extra {
    margin-bottom: 0;
  }

  .cart__extra-header {
    padding-left: 0;
    padding-right: 30px;
  }

  .cart__gift-left {
    padding-right: 20px;
    padding-left: 20px;
  }

  .cart__gift-img {
    right: -29px;
    width: 147px;
  }

  .cart__extra-list {
    padding-left: 0;
    padding-right: 30px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .cart__addition:hover .dish-bg-part {
    fill: var(--m-light-grey-50);
  }

  .cart__addition--selected:hover .dish-bg-part {
    fill: var(--m-yellow-50);
  }
}
