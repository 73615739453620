.header {

}

.header__top {
  position: fixed;
  z-index: 28;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  width: 240px;
}

.header__logo svg {
  width: 100%;
  height: auto;
}

.header__burger {
  width: 36px;
  height: 36px;
  position: relative;
}

.header__burger svg {
  width: 100%;
}

.header__promo {
  /* margin: 0 16px; */
  margin-bottom: 32px;
}

.header__mobile-nav {
  display: block;
  position: fixed;
  top: 66px;
  right: -100%;
  width: 100%;
  height: calc(100% - 66px);
  z-index: 28;
  background-color: white;
  transition: all 0.2s ease;
}

.header__mobile-nav--opened {
  right: 0;
}

.header__mobile-nav-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .header__mobile-nav {

  }

  .header__mobile-nav--opened {

  }
}

@media (min-width: 1440px) and (orientation: landscape) {
  .header__mobile-nav {

  }

  .header__mobile-nav--opened {

  }
}

.header__desktop-nav {
  display: none;
}

@media (min-width: 1440px) {
  .header {

  }

  .header--menu-opened {

  }

  .header__top {
    width: calc(100% - 64px);
    padding: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-left: 32px;
    margin-right: 32px;
    border-bottom: 2px solid var(--m-black);
  }

  .header__logo {
    width: 320px;
  }

  .header__burger {
    display: none;
  }

  .header__promo {
    margin: 0 32px;
    margin-bottom: 63px;
    height: 220px;
    font-size: 24px;
    line-height: 30px;
  }

  .header__menu--opened {

  }

  .header__desktop-nav {
    display: block;
  }

  .header__mobile-nav--opened {
    left: -100%;
  }
}
