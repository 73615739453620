.notification {
    position: fixed;
    z-index: 73;
    left: 0;
    top: 100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-top: 120px;
    padding: 42px 16px 140px;
    background-color: var(--m-yellow);
    transition: all 0.4s ease;
    /*overflow-y: auto;*/
    /*-webkit-overflow-scrolling: touch;*/
}

.notification--visible {
    top: 0;
    box-shadow: 2px 2px 100px var(--m-dark-grey);
}

.notification__close-icon {
    text-align: right;
    margin: 0 -9px -9px;
}

.notification__close-icon button {
    background: none;
    border: none;
}

.notification__header {

}

.notification__body .base-text {
    font-size: 18px;
    line-height: 24px;
}

.notification__footer .button {
    background-color: var(--m-white);
    color: var(--m-black);
    border-radius: 20px;
    padding: 5px 14px 4px;
    font-family: 'Geometria Regular';
    font-size: 20px;
}

.notification__footer .base-text {
    font-family: "Geometria Bold";
    font-size: 18px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .notification {
        top: 200%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 380px;
        height: auto;
        margin: 0;
        padding: 20px 26px 26px;
    }

    .notification--visible {
        top: 50%;
    }
}

@media (min-width: 1440px) {

}
