.payment {

}

.payment__inputs {

}

.payment__inputs .input-block {
  margin-bottom: 32px;
}

.payment__pay {
  margin-bottom: 24px;
}

.payment__pay-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment__pay-terms {
  display: flex;
  align-items: center;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
  cursor: pointer;
}

.payment__pay-terms span {
  margin-left: 8px;
}

.payment__methods {

}

.payment__method {
  position: relative;
  padding-left: 47px;
  cursor: pointer;
  border-bottom: 1px solid var(--m-gray);
}

.payment__method.divider {
  border-bottom: 1px solid var(--m-black);
}


.payment__method::before {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--m-black);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.payment__method::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  background-color: var(--m-black);
  opacity: 0;
  transition: all 0.2s ease;
}

.payment__method--active::after {
  opacity: 1;
}

.payment__method--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.payment__method-name {
  margin-bottom: 3px;
  padding-top: 16px;
  font-family: 'Geometria Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--m-black);
}

.payment__method--active .payment__method-name {
  font-family: 'Geometria Bold';
}

.payment__method-bottom {
  padding-bottom: 13px;
  font-family: 'Geometria Medium';
  font-size: 13px;
  line-height: 16px;
  color: var(--m-dark-grey);
}

@media (min-width: 768px) {
  .payment__inputs,
  .payment__pay {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .payment {

  }

  /* .payment__inputs,
  .payment__pay {
    width: 50%;
  } */

  .payment__inputs--bottom {
    padding-bottom: 0;
  }
}
